import {
    Paper,
    Box,
    Grid,
    ButtonBase,
    Typography,
    Avatar,
    Switch,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { getUserInfo } from '../utils/utils';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
import { styled } from '@mui/material/styles'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from "../redux/themeSlice"


function PerfilUsuario() {
    const userInfo = getUserInfo()
    const darkMode = useSelector((state) => state.theme.darkMode)
    const [currentTab, setCurrentTab] = useState(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    const handleConfirmLogout = () => {
        logout()
        setOpenDialog(false)
        window.location.reload()
    }

    const logout = () => {
        localStorage.clear()
    }

    const BoxPerfil = styled(Box)(({ theme }) => ({
        display: 'flex',
        '&:hover': theme.custom.shadowHover,
        transition: 'background-color 0.3s, box-shadow 0.3s',
        backgroundColor: currentTab === 0 ? theme.palette.primary.dark : null,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
    }))

    const BoxConfig = styled(Box)(({ theme }) => ({
        display: 'flex',
        '&:hover': theme.custom.shadowHover,
        transition: 'background-color 0.3s, box-shadow 0.3s',
        backgroundColor: currentTab === 1 ? theme.palette.primary.dark : null,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5
    }))

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Grid container sx={{ padding: 2 }}>
                <Grid item xs={12} md={12} lg={2} sx={{ height: 'fit-content', padding: 2, pt: 5, display: 'flex' }}>
                    <Paper sx={{ flex: 1 }}>
                        <BoxPerfil>
                            <ButtonBase sx={{ flex: 1, padding: 2, color: 'text.primary' }} onClick={() => setCurrentTab(0)}>
                                Perfil
                            </ButtonBase>
                        </BoxPerfil>

                        <BoxConfig>
                            <ButtonBase sx={{ flex: 1, padding: 2 }} onClick={() => setCurrentTab(1)}>
                                Opções
                            </ButtonBase>
                        </BoxConfig>
                    </Paper>
                </Grid>

                {currentTab == 0 ?
                    <TabGrid item xs={12} md={12} lg={10}>
                        <TabContainer container component={Paper}>
                            <Grid item xs={12} md={12} lg={12} xl={2} sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                <Typography>
                                    <Avatar sx={{
                                        boxShadow: 2,
                                        // ajusta o tamanho do avatar com base nos breakpoints
                                        width: { xs: 80, sm: 100, md: 120 },
                                        height: { xs: 80, sm: 100, md: 120 },
                                        '& .MuiSvgIcon-root': { // ajusta o tamanho do ícone dentro do avatar
                                            fontSize: { xs: 40, sm: 50, md: 60 },
                                        }
                                    }}>
                                        <PersonIcon />
                                    </Avatar>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12} xl={10} sx={{ p: 1 }}>
                                <Box sx={{ mb: 2 }}>
                                    <StyledTypography variant='body1'>
                                        Informações do Usuário
                                    </StyledTypography>
                                </Box>
                                <Typography variant="body2" gutterBottom>
                                    Nome
                                </Typography>
                                <UserInfoText variant="body1" gutterBottom>
                                    {userInfo.nome}
                                </UserInfoText>
                                <Typography variant="body2" gutterBottom>
                                    Email
                                </Typography>
                                <UserInfoText variant="body1" gutterBottom>
                                    {userInfo.email}
                                </UserInfoText>
                                <Typography variant="body2" gutterBottom>
                                    Telefone
                                </Typography>
                                <UserInfoText variant="body2">
                                    {userInfo.telefone}
                                </UserInfoText>
                            </Grid>
                        </TabContainer>
                    </TabGrid>
                    : currentTab === 1 ?
                        <TabGrid item xs={12} md={12} lg={10}>
                            <TabContainer container component={Paper}>
                                <OptionGridText item xs={12} md={6}>
                                    <StyledTypography variant='body2'>
                                        Modo Escuro
                                    </StyledTypography>
                                </OptionGridText>

                                <OptionGrid item xs={12} md={6}>
                                    <Switch size='small' checked={darkMode} onClick={() => dispatch(toggleTheme())} />
                                </OptionGrid>

                                <OptionGridText item xs={12} md={6}>
                                    <StyledTypography variant='body2'>
                                        Encerrar Sessão
                                    </StyledTypography>
                                </OptionGridText>

                                <OptionGrid item xs={12} md={6}>
                                    <Button size='small' variant="contained" color="error" onClick={handleClickOpen} sx={{ minWidth: 200 }}>
                                        Sair
                                    </Button>
                                </OptionGrid>
                            </TabContainer>
                        </TabGrid>
                        : null
                }
            </Grid>

            <Dialog
                open={openDialog}
                onClose={handleClose}
            >
                <DialogTitle>
                    Confirmar Logout
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Você tem certeza que deseja sair?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleConfirmLogout} color="error">
                        Sair
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

const TabContainer = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
}))

const TabGrid = styled(Grid)(({ theme }) => ({
    height: 'fit-content',
    display: 'flex',
    padding: theme.spacing(2),
}))

const UserInfoText = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.background.item,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))

const OptionGrid = styled(Grid)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
}))

const OptionGridText = styled(Grid)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    paddingLeft: theme.spacing(1),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
    whiteSpace: 'nowrap', // mantem o texto em uma linha
    overflow: 'hidden',   // esconde o que sai pra fora
    textOverflow: 'ellipsis', // renderiza 3 pontinhos pro texto que cortou
}))

export default PerfilUsuario
