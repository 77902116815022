import React, { useState } from "react";
import { Container, Typography, Box, Button, IconButton } from "@mui/material";
//Icones
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
//Style
import Divider from "@mui/material/Divider";

import carrosselStyle from "../estilos/CarrosselStyle"; // Importando os estilos
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import Slider, { SliderThumb } from "@mui/material/Slider";
//gif
import gifshot from "gifshot";
//imagens de exemplo
import Imagem1 from "../assets/images/oldboy.jpg";
import Imagem2 from "../assets/images/kapax.jpg";
import Imagem3 from "../assets/images/interestelar.jpg";
import Imagem4 from "../assets/images/usualSuspects.jpg";
import Imagem5 from "../assets/images/nosferatu.jpg";
import Imagem6 from "../assets/images/Iommi_sg.jpg";
import Imagem7 from "../assets/images/flyinV.jpg";
import Imagem8 from "../assets/images/lespaulZack.jpg";
import Imagem9 from "../assets/images/ouijaKirk.jpg";
import Imagem10 from "../assets/images/ltd.png";

const images = [
  Imagem1,
  Imagem2,
  Imagem3,
  Imagem4,
  Imagem5,
  Imagem6,
  Imagem7,
  Imagem8,
  Imagem9,
  Imagem10,
];

const numberOfFlipsToShow = 5;

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 2,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 4,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};

export default function Carrossel() {
  const theme = useTheme();
  const [current, setCurrent] = useState(2); // Começando pelo índice 2, que é a imagem 3
  const [range, setRange] = useState([0, images.length - 1]); // Estado para o intervalo do slider
  const [isPlaying, setIsPlaying] = useState(false); // Estado para controlar a reprodução

  const handlePrev = () => {
    setCurrent((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrent((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const getVisibleFlips = (currentIndex) => {
    const totalImages = images.length;
    let start = currentIndex - 2;
    let end = currentIndex + 2;

    // Ajusta se o start ou o end ultrapassarem os limites
    if (start < 0) {
      end -= start; // Se start é negativo, move o end para a direita
      start = 0;
    }
    if (end >= totalImages) {
      start -= end - totalImages + 1; // Se end ultrapassa o total, move o start para a esquerda
      end = totalImages - 1;
    }

    // Ajusta novamente se o start for negativo devido ao ajuste anterior
    if (start < 0) start = 0;

    // Calcula os índices visíveis com base no start e end ajustados
    return Array.from(
      { length: numberOfFlipsToShow },
      (_, i) => (start + i) % totalImages
    );
  };

  const visibleFlips = getVisibleFlips(current);

  const handleSliderChange = (event, newValue) => {
    setRange(newValue);
  };

  // Função para iniciar a reprodução das imagens
  let playInterval;

  const togglePlayPause = () => {
    if (isPlaying) {
      // Parar a reprodução
      clearInterval(playInterval);
      setIsPlaying(false);
    } else {
      // Iniciar a reprodução
      setIsPlaying(true);
      let currentIndex = range[0];

      const playImages = () => {
        if (currentIndex > range[1]) {
          clearInterval(playInterval);
          setIsPlaying(false);
        } else {
          setCurrent(currentIndex);
          currentIndex++;
        }
      };

      playImages();
      playInterval = setInterval(playImages, 1000); // 2000 ms = 2 segundos por imagem
    }
  };

  const exportGif = () => {
    const selectedImages = images.slice(range[0], range[1] + 1);

    gifshot.createGIF(
      {
        images: selectedImages,
        gifWidth: 500,
        gifHeight: 800,
        interval: 2,
      },
      function (obj) {
        if (!obj.error) {
          const animatedImage = obj.image;
          const downloadLink = document.createElement("a");
          downloadLink.href = animatedImage;
          downloadLink.download = "animated.gif";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
    );
  };

  return (
    <Container sx={carrosselStyle.container}>
      <Box sx={carrosselStyle.imagePlusTilte}>
        <Typography sx={carrosselStyle.titulo} variant="h4">
          SLIDER/PLAYER/FLIP DE IMAGENS
        </Typography>
        <Divider />
        <Box sx={carrosselStyle.conteudo}>
          <Box sx={carrosselStyle.imageBox}>
            <img
              src={images[current]}
              alt={`Imagem ${current + 1}`}
              style={{ width: "auto", height: "100%", objectFit: "contain" }}
            />
          </Box>
      </Box>
      <Divider />
        <Box sx={carrosselStyle.ihc}>
          <Box sx={carrosselStyle.sliderBox}>
            <AirbnbSlider
              components={{ Thumb: AirbnbThumbComponent }}
              value={range}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => value + 1}
              onChange={handleSliderChange}
              min={0}
              max={images.length - 1}
              marks
            />
          </Box>
          <Divider />
          <Box sx={carrosselStyle.interface}>
            <Box sx={carrosselStyle.playerBox}>
              <Button
                variant="contained"
                color="primary"
                onClick={togglePlayPause}
                startIcon={
                  isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />
                }
              >
                {isPlaying ? "Parar" : "Play"}
              </Button>
            </Box>

            <Box sx={carrosselStyle.lequeBox}>
              <IconButton onClick={handlePrev} disabled={current === 0}>
                <ArrowLeftRoundedIcon
                  sx={{ fontSize: 50, color: "primary.main" }}
                />
              </IconButton>

              {visibleFlips.map((index) => (
                <Box
                  key={index}
                  sx={{
                    ...carrosselStyle.flipBox,
                    border:
                      current === index
                        ? `3px solid ${theme.palette.primary.main}`
                        : `1px solid ${theme.palette.primary.main}`,
                    width: current === index ? 45 : 40,
                    height: current === index ? 45 : 40,
                    backgroundColor: current === index ? "" : "",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={current === index ? "primary" : "textSecondary"}
                  >
                    {index + 1}
                  </Typography>
                </Box>
              ))}

              <IconButton
                onClick={handleNext}
                disabled={current === images.length - 1}
              >
                <ArrowRightRoundedIcon
                  sx={{ fontSize: 50, color: "primary.main" }}
                />
              </IconButton>
            </Box>

            <Box sx={carrosselStyle.rangeGif}>
              <Button variant="contained" onClick={exportGif}>
                EXPORTAR EM .GIF
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
