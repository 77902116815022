import CadastroEdicaoGrupos from '../modulos/CadastroEdicaoGrupos';
import CadastroUsuarios from '../modulos/CadastroUsuarios';
import ModuloGrafico from '../modulos/ModuloGrafico';
import ModuloTabela from '../modulos/ModuloTabela';
import ModuloPrevisaoClimaticaComparada from '../modulos/ModuloPrevisaoClimaticaComparada';
import Carrossel from '../modulos/Carrossel'
import EnviaNotificacao from '../modulos/EnviaNotificacao'
import ModuloFiltroDataInicioFim from '../modulos/ModuloFiltroDataInicioFim';

export const componentMap = {
    cadastroEdicaoGrupos: CadastroEdicaoGrupos,
    cadastroUsuarios: CadastroUsuarios,
    moduloGrafico: ModuloGrafico,
    moduloTabela: ModuloTabela,
    ModuloPrevisaoClimaticaComparada: ModuloPrevisaoClimaticaComparada,
    Carrossel: Carrossel,
    EnviaNotificacao: EnviaNotificacao,
    ModuloFiltroDataInicioFim:ModuloFiltroDataInicioFim
}
