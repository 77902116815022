import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Paper, Box } from '@mui/material';
import { styled } from '@mui/system';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { componentMap } from './componentMap';

const TopBar = styled('div')(({ theme }) => ({
    zIndex: 10000,
    border: '2px solid black',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row'
}));

const TopBarIcon = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    ":hover": {
        color: theme.palette.primary.main
    },
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
}));

export default function ResponsiveGrid({ layouts, onLayoutChange }) {
    const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);
    const devMode = useSelector(state => state.dev.devMode);
    const dataIni = useSelector(state => state.dateFilterSlice.dataIni);
    const dataFim = useSelector(state => state.dateFilterSlice.dataFim);

    const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
    const cols = { lg: 12, md: 10, sm: 8, xs: 6, xxs: 4 };

    const handleRemoveComponent = (layouts, component_id, onLayoutChange) => {
        const aux = JSON.parse(JSON.stringify(layouts));
        const new_lg = aux.lg.filter(item => item.i !== component_id);
        aux.lg = new_lg;

        onLayoutChange(aux);
    };

    const handleLayoutChange = (newLayout) => {
        const updatedLayout = newLayout.map(item => {
            const originalItem = layouts.lg.find(origItem => origItem.i === item.i);
            return { ...item, type: originalItem?.type, params: originalItem?.params };
        });

        onLayoutChange({ lg: updatedLayout });
    };

    const renderComponent = (type, params) => {
        const ComponentToRender = componentMap[type];
        if (!ComponentToRender) {
            console.error(`Component of type '${type}' was not found in the list`);
            return null;
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'auto',
                boxSizing: 'border-box',
                height: '100%'
            }}>
                <ComponentToRender params={params} />
            </div>
        );
    };

    const memoizedChildren = useMemo(() => {
        return layouts.lg.map((item) => (
            <Box key={item.i} component={Paper}>
                {devMode &&
                    <TopBar>
                        <TopBarIcon className="drag-handle" style={{ width: '100%', cursor: 'grabbing' }}>
                            <OpenWithIcon />
                        </TopBarIcon>
                        <TopBarIcon onClick={() => handleRemoveComponent(layouts, item.i, onLayoutChange)}>
                            <DeleteForeverOutlinedIcon />
                        </TopBarIcon>
                    </TopBar>}
                {renderComponent(item.type, item.params)}
            </Box>
        ));
    }, [layouts, devMode, dataIni, dataFim, onLayoutChange]);  // Included dataIni and dataFim in dependencies

    return (
        <ResponsiveGridLayout
            key={`${dataIni}-${dataFim}`}
            cols={cols}
            draggableHandle=".drag-handle"
            breakpoints={breakpoints}
            layouts={layouts}
            onLayoutChange={handleLayoutChange}
            isResizable={devMode}
            style={{ overflow: 'hidden', minHeight: '100vh' }}
        >
            {memoizedChildren}
        </ResponsiveGridLayout>
    );
}
