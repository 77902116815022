import React, { useState } from "react"
import api from "../services/api"
import { getUserToken } from "../utils/utils"
import { v4 as uuidv4 } from 'uuid'
import { Box, Drawer, Button, Typography } from "@mui/material"
import { componentMap } from "../responsiveGrid/componentMap"
import AlertSnackbar from "../componentes/AlertSnackbar"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ParamDialog from "./DevToolsParamDialog"
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

export default function DevTools({ layouts, currentPageId, onLayoutChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const componentList = Object.keys(componentMap)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [openParamDialog, setOpenParamDialog] = useState(false)
    const [currentSelectedComponent, setCurrentSelectedCompent] = useState('')

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setSnackbarOpen(false)
    }

    const handleOpenSnackbar = (mensagem, severity) => {
        setSnackbarMessage(mensagem)
        setSnackbarSeverity(severity)
        setSnackbarOpen(true)
    }

    const toggleDrawer = () => {
        setIsOpen(!isOpen)
    }

    const handleOpenParamDialog = async (componentType) => {
        setCurrentSelectedCompent(componentType)
        setOpenParamDialog(true)
    }

    const handleAddComponent = (params) => {
        let aux = JSON.parse(JSON.stringify(layouts))
        aux.lg = [...aux.lg, { type: currentSelectedComponent, params: params, i: uuidv4(), x: 0, y: 4, w: 4, h: 2 }]

        onLayoutChange(aux)
    }

    const atualizarLayout = async () => {
        try {
            const response = await api.put('dev_tools/atualizar_layout', { id_pagina: currentPageId, layouts: layouts }, { headers: { Authorization: getUserToken() } })
            if (response.status === 200) {
                handleOpenSnackbar('Alterações salvas com sucesso!', 'success')
            }
        } catch (error) {
            if (error.response) {
                console.log(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`)
                handleOpenSnackbar(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`, 'error')
            } else if (error.request) {
                console.log("Erro de rede ou servidor não respondeu")
            } else {
                console.log("Erro ao configurar a requisição")
            }
        }
    }

    return (
        <Box>
            {!isOpen && (
                <Box sx={{
                    position: 'fixed',
                    top: '50%',
                    right: 0,
                    transform: 'translateY(-50%)',
                    zIndex: 900,
                }}>
                    <Button onClick={toggleDrawer}><ArrowBackIosNewRoundedIcon /></Button>
                </Box>
            )}

            <Drawer
                anchor="right"
                open={isOpen}
                onClose={toggleDrawer}
                variant="persistent"
                style={{ zIndex: 1000 }}
            >
                <Button size='small' onClick={toggleDrawer}><CloseRoundedIcon /></Button>

                <Box sx={{ padding: 2 }}>
                    <Box sx={{ border: '1px solid gray', p: 1, mb: 2, borderRadius: 1 }}>
                        <Typography sx={{ border: '1px solid gray', p: 1, borderRadius: 1 }}>Lista de Módulos</Typography>
                        <List component="nav" aria-label="mailbox folders">
                            {componentList.map((component_type, index) => (
                                <React.Fragment key={index}>
                                    <ListItemButton onClick={() => handleOpenParamDialog(component_type)}>
                                        <ListItemText primary={component_type} />
                                    </ListItemButton>
                                    {index < componentList.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    </Box>

                    <Box sx={{ p: 1, border: '1px solid gray', borderRadius: 1 }}>
                        <Button size='small' variant="contained" color='primary' onClick={atualizarLayout}>Salvar Edições</Button>
                    </Box>
                </Box>
            </Drawer>

            <AlertSnackbar
                open={snackbarOpen}
                handleClose={handleCloseSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
                horizontalAnchor="center"
                verticalAnchor="bottom"
                slideDirection="up"
            />

            <ParamDialog
                onSaveComponent={(params) => handleAddComponent(params)}
                open={openParamDialog}
                handleClose={() => setOpenParamDialog(false)}
                componentType={currentSelectedComponent} />
        </Box>
    )
}