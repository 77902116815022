import React, { useState } from "react";
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  useTheme,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { Link } from "@mui/material";
// Icons
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
//Recuperar Senha
import RecuperacaoSenha from "../componentes/RecuperarSenha";
//Logo
import logSmalleEquilibrio from "../assets/images/logo-small-equilibrio.png";
//Estilos
import {
  boxloginBorder,
  gridStyle,
  body2style,
  textStyle,
  gridStyle2,
  buttonStyle,
  recupStyle,
} from "../estilos/componenteCaixaLoginStyle";

export default function ComponenteCaixaLogin() {
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");

  const theme = useTheme();
  const [isDisable, setIsDisable] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  //API
  const handleLogin = async () => {
    setIsDisable(true); // Disable botão
    try {
      const response = await api.post("/gestao/login", {
        email: username,
        senha: password,
      });

      if (response.status === 200) {
        let token = response.data.token;
        let usuario = response.data.usuario_info;
        localStorage.setItem("token", token);
        localStorage.setItem("usuario", JSON.stringify(usuario));
        window.location.reload()
      }
    } catch (error) {
      console.error("Erro ao logar", error);

      if (error.response) {
        alert(
          error.response.data.error ||
          "Occorreu um erro, tente novamente mais tarde."
        );
      } else {
        alert("Occorreu um erro, tente novamente mais tarde.");
      }
    } finally {
      setIsDisable(false);
    }
  };

  //Recuperação de Senha
  const [mostrarRecuperacaoSenha, setMostrarRecuperacaoSenha] = useState(false);

  const handleForgotPassword = () => {
    setMostrarRecuperacaoSenha(true);
  };

  const handleCloseRecuperacao = () => {
    setMostrarRecuperacaoSenha(false);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={boxloginBorder(theme)}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={gridStyle}
      >
        <img src={logSmalleEquilibrio} height={120} alt="Equilibrio Logo" />
        <Typography variant="body2" sx={body2style}>
          Seja Bem-vindo!
        </Typography>
      </Grid>

      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            label="Usuário"
            fullWidth
            size="small"
            value={username}
            onChange={handleUsernameChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
            sx={textStyle}
          />
        </Grid>

        <Grid item sx={gridStyle2}>
          <TextField
            label="Senha"
            type="password"
            fullWidth
            size="small"
            value={password}
            onChange={handlePasswordChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
            sx={textStyle}
          />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            size="small"
            disabled={isDisable}
            fullWidth
            onClick={handleLogin}
            sx={buttonStyle}
          >
            Acessar
          </Button>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" sx={recupStyle}>
        <Typography variant="body2" sx={recupStyle}>
          <Link
            component="button"
            variant="body2"
            onClick={handleForgotPassword}
            sx={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Esqueceu a senha?
          </Link>
          {mostrarRecuperacaoSenha && (
            <RecuperacaoSenha
              open={mostrarRecuperacaoSenha}
              onClose={handleCloseRecuperacao}
            />
          )}
        </Typography>
      </Grid>
    </Box>
  );
}
