import React from 'react';
import { styled } from '@mui/system';
import { Sidebar, Menu, MenuItem, menuClasses, } from 'react-pro-sidebar';
import { Box, Typography, Collapse, Switch, ButtonBase, Divider } from '@mui/material';
import { toggleTheme } from "../redux/themeSlice"
import { toggleDevMode } from '../redux/devSlice';
import { setCurrentPage, clearPage } from '../redux/pageSlice';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../assets/images/logo_equilibrio.png'
import { useTheme } from '@mui/material/styles';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import UserWidget from './UserWidget';
import { isDev } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import * as Icons from '@mui/icons-material';


export default function BarraLateral() {
  const [collapsed, setCollapsed] = React.useState(false)
  const paginas = useSelector((state) => state.page.pages)
  const darkMode = useSelector((state) => state.theme.darkMode)
  const currentPageId = useSelector((state) => state.page.currentPage.id)
  const devMode = useSelector((state) => state.dev.devMode)
  const dispatch = useDispatch()
  const theme = useTheme()
  const navigate = useNavigate()


  const handleChangeDashboard = (pagina) => {
    navigate('/dashboard')
    dispatch(setCurrentPage(pagina))
  }

  const handleGoBack = () => {
    navigate('/')
    dispatch(clearPage())
  }

  const BoxStyled = styled(Box)(({ theme }) => ({
    height: 20,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 5
  }))



  return (
    <Box sx={{ position: 'relative' }}>
      <ButtonBase sx={{
        backgroundColor: 'background.paper', // Uma cor legal
        width: 40, // Tamanho do círculo
        height: 40, // Tamanho do círculo
        borderRadius: '50%', // Torna o Box redondo
        top: '50%',
        position: 'absolute',
        right: -20,
        zIndex: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: [2], // Sombra
        transition: 'transform 0.1s ease-in-out', // Animação suave
        '&:hover': {
          transform: 'scale(1.1)', // Efeito de hover
        }
      }} onClick={() => setCollapsed(!collapsed)} >
        {collapsed ? <ArrowForwardIosRoundedIcon sx={{ fontSize: '16px' }} /> : <ArrowBackIosNewRoundedIcon sx={{ fontSize: '16px' }} />}
      </ButtonBase>

      <Sidebar
        backgroundColor={darkMode ? '#1D272F' : 'white'}
        collapsed={collapsed}
        rootStyles={{
          borderColor: darkMode ? '#2F363C' : '#efefef',
          height: '100vh',
          transition: 'all 300ms ease, border-color 0s'
        }}>
        <Box onClick={handleGoBack} sx={{ display: 'flex', pl: 3, alignItems: 'center', cursor: 'pointer', height: '10%', }}>
          <img src={logo} width={35} height={35} style={{ borderRadius: 5 }} alt="Logo Equilibrio" />

          <Collapse in={!collapsed} unmountOnExit timeout="auto">
            {!collapsed && <Box sx={{ ml: 2 }}>
              <Typography variant='h6' color='primary' sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Dashboard</Typography>
            </Box>}
          </Collapse>
        </Box>

        <Menu
          style={{ overflow: 'auto' }}
          rootStyles={{
            [`.${menuClasses.button}`]: {
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.grey[50]
              }
            }
          }}
        >
          <Collapse in={!collapsed} unmountOnExit timeout="auto">
            <Box sx={{ fontSize: '13px', fontWeight: 'bold', color: theme.palette.primary.main, padding: '10px' }}>
              {!collapsed && 'Páginas'}
            </Box>
          </Collapse>
          {paginas.map((pagina, index) => {
            const IconComponent = Icons[pagina.icone] ? Icons[pagina.icone] : Icons.Dashboard;
            return (
              <MenuItem
                key={pagina.id} // It's better to use unique IDs rather than index for keys
                icon={<IconComponent />} // Render the dynamic icon component
                onClick={() => handleChangeDashboard(pagina)}
                style={{ backgroundColor: currentPageId === pagina.id ? theme.palette.primary.dark : null }}
              >
                <Typography variant='body2'>
                  {pagina.nome_pagina}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>

        <Collapse in={!collapsed} unmountOnExit timeout="auto" sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          bgcolor: 'background.paper',
        }}>

          {isDev() ?
            <Box sx={{ pl: 2, }}>
              <Divider />

              <Box>
                <Typography variant='caption'>Modo Dev:</Typography><Switch size='small' onClick={() => dispatch(toggleDevMode())} checked={devMode} />
                <Typography variant='caption'>Modo Escuro:</Typography> <Switch size='small' checked={darkMode} onClick={() => dispatch(toggleTheme())} />
              </Box>
            </Box>
            : null}

          <UserWidget />
        </Collapse>
      </Sidebar>
    </Box>
  )
}



