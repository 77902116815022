import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { getUserToken } from '../utils/utils';
import { Box, Tabs, Tab, Grid, TextField, FormControlLabel, Checkbox, Button, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';


export default function CadastroEdicaoGrupos({ params }) {
  // Page State
  const [tabValue, setTabValue] = useState(0);
  const [isDisableSave, setIsDisableSave] = useState(false);
  const [isDisableEdit, setIsDisableEdit] = useState(false);

  //Add Grupo
  const [newGrupoNome, setNewGrupoNome] = useState('');
  const [selectedPages, setSelectedPages] = useState([]);
  const [pages, setPages] = useState([]);

  //Edit Grupo
  const [grupos, setGrupos] = useState('');
  const [editNewGrupoNome, setEditNewGrupoNome] = useState('');
  const [selectedGrupo, setSelectedGrupo] = useState([]);
  const [selectedPagesEdit, setSelectedPagesEdit] = useState([]);

  useEffect(() => {
    fetchPages()
    fetchGrupos()
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    handleClearCampos()
    setSelectedPagesEdit([])
  };

  const handleClearCampos = () => {
    setEditNewGrupoNome('')
  };

  const handlePageChange = (event) => {
    const pageId = parseInt(event.target.name.replace('add-', ''));
    setSelectedPages(prevPages => {
      if (prevPages.includes(pageId)) {
        return prevPages.filter(id => id !== pageId);
      } else {
        return [...prevPages, pageId];
      }
    });
  };

  const handlePageChangeEdit = (event) => {
    const pageId = parseInt(event.target.name.replace('edit-', ''));
    setSelectedPagesEdit(prevPages => {
      if (prevPages.includes(pageId)) {
        // If the page is already in the array, remove it
        return prevPages.filter(id => id !== pageId);
      } else {
        // If the page is not in the array, add it
        return [...prevPages, pageId];
      }
    });
  };

  const handleGrupoSelect = (event, newValue) => {
    setSelectedGrupo(newValue);
    if (newValue && newValue.paginas) {
      // Extracting the IDs of the pages from the selected group
      const pageIds = newValue.paginas.map(pagina => pagina.id);
      setSelectedPagesEdit(pageIds);
    } else {
      // Resetting selected pages if no group is selected
      setSelectedPagesEdit([]);
    }
  };

  //API CALL FUNCTIONS
  const fetchPages = async () => {
    try {
      const response = await api.get('/gestao/retornar_paginas', {
        headers: { 'Authorization': getUserToken() }
      });
      if (response.status === 200) {
        setPages(response.data.paginas);
      }

    } catch (error) {
      const errorMessage = error.response?.data.error || 'Ocorreu um erro. Tente novamente mais tarde.';
      alert(errorMessage);
    }
  };

  const fetchGrupos = async () => {
    try {
      const response = await api.get('/gestao/retornar_grupos', {
        headers: { 'Authorization': getUserToken() }
      });
      if (response.status === 200) {
        setGrupos(response.data.grupos);
      }

    } catch (error) {
      const errorMessage = error.response?.data.error || 'Ocorreu um erro. Tente novamente mais tarde.';
      alert(errorMessage);
    }
  };

  const handleAddGroup = async () => {
    if (newGrupoNome === '') {
      alert('Nome do grupo é necessário');
      return;
    }
    if (selectedPages.length === 0) {
      alert('Selecione as páginas referentes ao grupo');
      return;
    }
    setIsDisableSave(true);

    try {
      const resposta = await api.post('/gestao/criar_grupo', {
        'nome_grupo': newGrupoNome,
        'paginas': selectedPages,
      }, {
        headers: { 'Authorization': getUserToken() }
      });

      if (resposta.status === 200) {
        alert(resposta.data.mensagem);
      }
    } catch (erro) {

      if (erro.response) {
        alert(erro.response.data.error || 'Ocorreu um erro. Por favor, tente novamente mais tarde.');
      } else {
        alert('Ocorreu um erro. Por favor, tente novamente mais tarde.');
      }
    } finally {
      setIsDisableSave(false);
      fetchGrupos()
    }
  };

  const handleEditGroup = async () => {
    setIsDisableEdit(true);
    try {
      const resposta = await api.post('/gestao/editar_grupo', {
        'id': selectedGrupo.id, 'nome': editNewGrupoNome,
        'paginas': selectedPagesEdit,
      }, {
        headers: { 'Authorization': getUserToken() }
      });

      if (resposta.status === 200) {
        alert(resposta.data.mensagem);
      }
    } catch (erro) {

      if (erro.response) {
        alert(erro.response.data.error || 'Ocorreu um erro. Por favor, tente novamente mais tarde.');
      } else {
        alert('Ocorreu um erro. Por favor, tente novamente mais tarde.');
      }
    } finally {
      setIsDisableEdit(false);
      fetchGrupos()
      setEditNewGrupoNome('')
    }
  };

  const handleRemoveGrupo = async () => {
    setIsDisableEdit(true);
    try {
      const resposta = await api.post('/gestao/remover_grupo', {
        'id': selectedGrupo.id,
      }, {
        headers: { 'Authorization': getUserToken() }
      });

      if (resposta.status === 200) {
        alert(resposta.data.mensagem);
      }
    } catch (erro) {
      if (erro.response) {
        alert(erro.response.data.error || 'Ocorreu um erro. Por favor, tente novamente mais tarde.');
      } else {
        alert('Ocorreu um erro. Por favor, tente novamente mais tarde.');
      }
    } finally {
      setIsDisableEdit(false);
      fetchGrupos()
      setEditNewGrupoNome('')
    }
  };

  return (
    <Box
      sx={{
        width: '100%',


      }}
    >
      <Grid
        container
        direction="column" // Stack children vertically
        alignItems="center" // Align children horizontally in the center
        justifyContent="center" // Align children vertically in the center
        sx={{
          p: 1,
          width: 'auto',
        }}
      >
        <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ mb: 2 }}>
          <Tab label="Adicionar Grupos" />
          <Tab label="Editar Grupos" />
        </Tabs>

        {tabValue === 0 && (
          <Grid
            container
            direction="column"
            item
            spacing={2}
            sx={{
              pl: 4,
              pr: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start', // Align items to the start (top)
              width: 400,
              ml: 0,
              height: '100%',
            }} >
            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <TextField
                size='small'
                fullWidth
                label="Nome do Grupo"
                value={newGrupoNome}
                onChange={(e) => setNewGrupoNome(e.target.value)}
              />
            </Grid>

            <Grid item sx={{ width: '100%', mt: 0, mb: -1, pl: 3, pr: 3 }}>
              <Typography variant="h6" component="h2" gutterBottom>
                Selecione as Páginas de Acesso para o Grupo:
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{
              width: '100%',
              paddingLeft: 3,
              paddingRight: 3,
              maxHeight: '300px', // Set a fixed maximum height
              overflow: 'auto'    // Enable scrolling
            }}>
              {pages.map(page => (
                <Grid container key={page.id}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedPages.includes(page.id)}
                          onChange={handlePageChange}
                          name={'add-' + page.id.toString()}
                        />

                      }
                      label={page.nome_pagina}
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              ))}

            </Grid>

            <Grid item sx={{ width: '75%', paddingLeft: 3, paddingRight: 3, mt: -2 }} >
              <Button
                variant="contained"
                size="small"
                disabled={isDisableSave}
                fullWidth
                onClick={handleAddGroup}
                sx={{
                  borderRadius: '16px',
                  textTransform: 'none',
                  mt: 2,
                }}>
                Adicionar Grupo
              </Button>
            </Grid>
          </Grid>
        )}

        {tabValue === 1 && (
          <Grid
            container
            direction="column"
            item
            spacing={2}
            sx={{
              pl: 4,
              pr: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start', // Align items to the start (top)
              width: 400,
              ml: 0,
              height: '100%',
            }} >

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <Autocomplete
                id="grupo-autocomplete"
                size="small"
                options={grupos}
                getOptionLabel={(option) => option.nome}
                onChange={handleGrupoSelect}
                renderInput={(params) => <TextField {...params} label="Select Grupo" fullWidth />}
              />
            </Grid>
            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <TextField
                size='small'
                fullWidth
                label="Editar nome do Grupo"
                value={editNewGrupoNome}
                onChange={(e) => setEditNewGrupoNome(e.target.value)}
              />
            </Grid>

            <Grid item sx={{ width: '100%', mt: 0, mb: -1, pl: 3, pr: 3 }}>
              <Typography variant="h6" component="h2" gutterBottom>
                Selecione as Páginas de Acesso para o Grupo:
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{
              width: '100%',
              paddingLeft: 3,
              paddingRight: 3,
              maxHeight: '300px', // Set a fixed maximum height
              overflow: 'auto'    // Enable scrolling
            }}>
              {pages.map(page => (
                <Grid container key={page.id}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedPagesEdit.includes(page.id)}
                          onChange={handlePageChangeEdit}
                          name={'edit-' + page.id.toString()}
                        />
                      }
                      label={page.nome_pagina}
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item sx={{ width: '75%', paddingLeft: 3, paddingRight: 3, mt: -1 }} >
              <Button
                variant="contained"
                size="small"
                fullWidth
                onClick={handleEditGroup}
                disabled={isDisableEdit}
                sx={{
                  borderRadius: '16px',
                  textTransform: 'none',
                  mt: 1
                }}>
                Salvar Alterações
              </Button>
            </Grid>

            <Grid item sx={{ width: '75%', paddingLeft: 3, paddingRight: 3, mt: -1 }} >
              <Button
                variant="contained"
                size="small"
                fullWidth
                disabled={isDisableEdit}
                onClick={handleRemoveGrupo}
                sx={{
                  borderRadius: '16px',
                  textTransform: 'none',
                  bgcolor: 'error.main',
                  color: 'common.white',
                  '&:hover': {
                    bgcolor: 'error.dark',
                  },
                  '&:disabled': {
                    bgcolor: 'action.disabledBackground',
                    color: 'action.disabled',
                  }
                }}>
                Remover Grupo
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );

}
