import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Rotas from './rotas/Rotas';
import useGlobalTheme from './estilos/useGlobalTheme';


export default function App() {
  const theme = useGlobalTheme()

  return (
    <div style={{ overflow: 'hidden' }}>
      <ThemeProvider theme={theme}> {/* tema do MUI */}
        <CssBaseline /> {/* faz o html base da pagina herdar o estilo do material ui */}
        <Rotas /> {/* rotas do projeto */}
      </ThemeProvider>
    </div>
  )
}
