import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BarraLateral from './BarraLateral';
import api from '../services/api';
import { useDispatch } from 'react-redux';
import { setPages } from '../redux/pageSlice';
import { getUserToken } from '../utils/utils';
import { setNotifications } from '../redux/notificationSlice';
import { authentication } from '../utils/utils';
import { isDev } from '../utils/utils';

export default function AppWrapper({ children }) {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const shouldShowSidebar = location.pathname !== '/login'

    // o retorna paginas ta aqui por conta que é o lugar na hierarquia mais alto depois do App.js, e essa chamada sempre vai precisar ocorrer
    const retornaPaginas = async () => {
        try {
            if (isDev()) {
                var response = await api.get('dev_tools/retornar_paginas', { headers: { Authorization: getUserToken() } })
            }
            else {
                var response = await api.get('gestao/retornar_paginas_usuario', { headers: { Authorization: getUserToken() } })
            }

            dispatch(setPages(response.data.paginas))

        } catch (error) {
            if (error.response) {
                alert(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`)
            } else if (error.request) {
                alert("Erro de rede ou servidor não respondeu")
            } else {
                alert(`Erro ao configurar a requisição`)
            }
        }
    }

    const retornaNotifications = async () => {
        try {
            var response = await api.get('gestao/retornar_notificacoes_usuario', { headers: { Authorization: getUserToken() } })
            dispatch(setNotifications(response.data.notificacoes))

        } catch (error) {
            if (error.response) {
                alert(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`)
            } else if (error.request) {
                alert("Erro de rede ou servidor não respondeu")
            } else {
                alert("Erro ao configurar a requisição")
            }
        }
    }

    useEffect(() => {
        const init = async () => {
            await retornaPaginas()
            await retornaNotifications()
        }

        if (authentication()) { // init ocorre quando o usuário ta logado
            init()
            navigate('/')
        } else {
            localStorage.clear()
            navigate('/login')
        }
    }, [])

    return (
        <div style={{ display: 'flex', height: '100vh', width: '100vw', overflow: 'hidden' }}>
            {shouldShowSidebar && authentication() && <BarraLateral />}

            <div style={{ flex: 1, overflow: 'auto' }}>
                {children}
            </div>
        </div>
    )
}
