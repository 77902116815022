import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, Typography } from '@mui/material';
import { setDataIni, setDataFim } from '../redux/dateFilterSlice'; // Ensure the path to your Redux slice is correct

function FiltroDataInicioFim() {
  const dispatch = useDispatch();
  const reduxStartDate = useSelector(state => state.dateFilterSlice.dataIni);
  const reduxEndDate = useSelector(state => state.dateFilterSlice.dataFim);

  // Local states to hold the temporary input values
  const [startDate, setStartDate] = useState(reduxStartDate);
  const [endDate, setEndDate] = useState(reduxEndDate);

  // Update Redux on button click
  const updateDates = () => {
    dispatch(setDataIni(startDate));
    dispatch(setDataFim(endDate));
    console.log('Data updated:', startDate, endDate); // Optional: Log updated dates
  };

  return (
    <div style={{
      marginTop: '10px', // Adds 10px margin to the top
      marginLeft: '10px', // Adds 10px margin to the left
    }}>
      <Typography variant="h6" gutterBottom>
        Intervalo dos dados
      </Typography>
      <TextField
        label="Data de Início"
        type="date"
        value={startDate || ''}
        onChange={e => setStartDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ marginRight: 10, marginBottom: 10 }} // Added marginBottom for spacing, keep marginRight to separate fields
      />
      <TextField
        label="Data de Fim"
        type="date"
        value={endDate || ''}
        onChange={e => setEndDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ marginBottom: 10 }} // Added marginBottom for spacing
      />
      <Button onClick={updateDates} variant="outlined" color="secondary">
        Filtrar
      </Button>
    </div>
  );
}

export default FiltroDataInicioFim;
