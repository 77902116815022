import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { getUserToken } from '../utils/utils';

import { Box, Grid, TextField, Button, Tabs, Tab, InputAdornment, Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputMask from 'react-input-mask';

// Icons
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function CadastroUsuarios({ params }) {
  // Page State
  const [tabValue, setTabValue] = useState(0);
  const [isDisableSave, setIsDisableSave] = useState(false);
  const [isDisableEdit, setIsDisableEdit] = useState(false);

  //Autocomplete
  const [userList, setUserList] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  // User Input States
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userName, setUserName] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userPassword2, setUserPassword2] = useState('');
  const [userGrupo, setUserGrupo] = useState(null);
  const [showPassword1, setShowPassword1] = useState(false);

  const [editUserEmail, setEditUserEmail] = useState('');
  const [editUserPhone, setEditUserPhone] = useState('');
  const [editUserName, setEditUserName] = useState('');
  const [editUserPassword, setEditUserPassword] = useState('');
  const [editUserPassword2, setEditUserPassword2] = useState('');
  const [editUserGrupo, setEditUserGrupo] = useState(null);

  useEffect(() => {
    fetchGrupos()
    fetchUsuarios()

  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    handleClearCampos();
  };

  const handleClearCampos = () => {
    setUserEmail('')
    setUserPhone('')
    setUserName('')
    setUserPassword('')
    setUserPassword2('')
    setUserGrupo(null)
    setEditUserEmail('')
    setEditUserPhone('')
    setEditUserName('')
    setEditUserPassword('')
    setEditUserPassword2('')
    setEditUserGrupo(null)
    setSelectedUser(null)
  };

  const handleAutocompleteOnChangeSelectUser = (newValue) => {
    setSelectedUser(newValue);
    setEditUserEmail(newValue ? newValue.email : '');
    setEditUserPhone(newValue ? newValue.telefone : '');
    setEditUserName(newValue ? newValue.nome : '');
    setEditUserPassword('');

    // Handle the selection or clearing of a group
    if (Array.isArray(newValue) && newValue.length === 0) {
      // Handle the case where newValue is an empty array
      setEditUserGrupo(null);
    } else {
      // Find the user group using the group id from newValue
      const userGrupo = grupos.find(grupo => grupo.id === newValue?.grupo_id);
      setEditUserGrupo(userGrupo || null);
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword1(!showPassword1);
  };

  //API CALL FUNCTIONS
  const fetchGrupos = async () => {
    try {
      const response = await api.get('/gestao/retornar_grupos', {
        headers: { 'Authorization': getUserToken() }
      });

      if (response.status === 200) {
        setGrupos(response.data.grupos);
      }

    } catch (error) {
      console.error('Erro ao obter paginas:', error);
      const errorMessage = error.response?.data.error || 'Ocorreu um erro. Tente novamente mais tarde.';
      alert(errorMessage);
    }
  };

  const fetchUsuarios = async () => {
    try {
      const response = await api.get('/gestao/retornar_usuario', {
        headers: { 'Authorization': getUserToken() }
      });

      if (response.status === 200) {
        setUserList(response.data.usuarios);
      }

    } catch (error) {
      console.error('Erro ao obter paginas:', error);
      const errorMessage = error.response?.data.error || 'Ocorreu um erro. Tente novamente mais tarde.';
      alert(errorMessage);
    }
  };

  const handleAddUser = async () => {
    const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const regexTelefone = /^\+55 \(\d{2}\) \d{4,5}-\d{4}$/;
    if (!userGrupo || !userGrupo.id) {
      alert('Por favor, selecione um grupo válido.');
      return;
    }
    if (!regexEmail.test(userEmail)) {
      alert('Por favor, insira um endereço de email válido. Exemplo: usuario@example.com');
      return;
    }
    if (!regexTelefone.test(userPhone)) {
      alert('Por favor, insira um número de telefone com formato válido. Exemplo: +55 (11) 99999-9999');
      return;
    }
    if (userPassword !== userPassword2) {
      alert('senhas não coincidem');
      return;
    }
    setIsDisableSave(true);
    try {
      const resposta = await api.post('/gestao/criar_usuario', {
        'email': userEmail, 'senha': userPassword, 'nome': userName,
        'telefone': userPhone, 'grupo_id': userGrupo.id
      }, {
        headers: { 'Authorization': getUserToken() }
      });

      if (resposta.status === 200) {
        alert(resposta.data.mensagem);
      }
    } catch (erro) {
      console.error('Erro no login:', erro);

      if (erro.response) {
        alert(erro.response.data.error || 'Ocorreu um erro. Por favor, tente novamente mais tarde.');
      } else {
        alert('Ocorreu um erro. Por favor, tente novamente mais tarde.');
      }
    } finally {
      setIsDisableSave(false);
      fetchUsuarios();
      handleClearCampos();
    }
  };

  const handleEditUser = async () => {
    const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const regexTelefone = /^\+55\s\(\d{2}\)\s\d{4,5}-\d{4}$/;
    if (editUserPassword !== editUserPassword2) {
      alert('senhas não coincidem');
      return;
    }
    if (!regexEmail.test(editUserEmail)) {
      alert('Por favor, insira um endereço de email válido.');
      return;
    }
    if (!regexTelefone.test(editUserPhone)) {
      alert('Por favor, insira um número de telefone brasileiro válido.');
      return;
    }

    setIsDisableEdit(true);
    try {
      const resposta = await api.post('/gestao/editar_usuario',
        {
          'id': selectedUser.id,
          'grupo_id': editUserGrupo.id,
          'nome': editUserName,
          'email': editUserEmail,
          'senha': editUserPassword,
          'telefone': editUserPhone
        },
        { headers: { 'Authorization': getUserToken() } });

      if (resposta.status === 200) {
        alert(resposta.data.mensagem);
      }
    } catch (erro) {
      console.error('Erro no login:', erro);

      if (erro.response) {
        alert(erro.response.data.error || 'Ocorreu um erro. Por favor, tente novamente mais tarde.');
      } else {
        alert('Ocorreu um erro. Por favor, tente novamente mais tarde.');
      }
    } finally {
      setIsDisableEdit(false);
      fetchUsuarios();
      handleClearCampos();

    }
  };

  const handleRemoveUser = async () => {
    const isConfirmed = window.confirm("Você realmente deseja remover este usuário?");

    if (!isConfirmed) {
      return;
    }

    setIsDisableEdit(true);
    try {
      const resposta = await api.post('/gestao/remover_usuario',
        { 'id_usuario': selectedUser.id }, { headers: { 'Authorization': getUserToken() } });

      if (resposta.status === 200) {
        alert(resposta.data.mensagem);
      }
    } catch (erro) {
      console.error('Erro no login:', erro);

      if (erro.response) {
        alert(erro.response.data.error || 'Ocorreu um erro. Por favor, tente novamente mais tarde.');
      } else {
        alert('Ocorreu um erro. Por favor, tente novamente mais tarde.');
      }
    } finally {
      setIsDisableEdit(false);
      fetchUsuarios();
      handleClearCampos();
    }
  };

  return (
    <Box
      sx={{
        width: '100%',


      }}
    >
      <Grid
        container
        direction="column" // Stack children vertically
        alignItems="center" // Align children horizontally in the center
        justifyContent="center" // Align children vertically in the center
        sx={{
          p: 1,
          width: 'auto',
        }}
      >
        <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ marginBottom: 2 }}>
          <Tab label="Adicionar Usuário" />
          <Tab label="Editar Usuário" />
        </Tabs>

        {tabValue === 0 && (
          <Grid
            item
            container
            direction="column"
            spacing={2}
            sx={{
              pl: 4,
              pr: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start', // Align items to the start (top)
              width: 400,
              ml: 0,
              height: '100%',
            }}


          >
            <Grid item sx={{
              mt: 3, width: '100%', paddingLeft: 3, paddingRight: 3, marginTop: 0,
            }} >
              <Autocomplete

                size="small"
                options={grupos}
                value={userGrupo}
                getOptionLabel={(option) => option?.nome ?? ""}
                renderInput={(params) => (
                  <TextField {...params} label="Selecione o Grupo" fullWidth />
                )}
                onChange={(event, newValue) => {
                  setUserGrupo(newValue);
                }}
              />
            </Grid>


            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <TextField
                label="Nome"
                fullWidth
                size="small"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <TextField
                label="Email"
                fullWidth
                size="small"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <InputMask
                mask="+55 (99) 99999-9999"
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    label="Telefone"
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <TextField
                label="Senha"
                type={showPassword1 ? "text" : "password"}
                fullWidth
                size="small"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3, mb: 1 }} >
              <TextField
                label="Confirmar Senha"
                type={showPassword1 ? "text" : "password"}
                fullWidth
                size="small"
                value={userPassword2}
                onChange={(e) => setUserPassword2(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword1}
                    onChange={handlePasswordVisibility}
                  />
                }
                label="Mostrar senha"
              />
            </Grid>

            <Grid item sx={{ width: '75%', paddingLeft: 3, paddingRight: 3, mb: 1 }} >
              <Button
                variant="contained"
                size="small"
                fullWidth
                onClick={handleAddUser}
                disabled={isDisableSave}
                sx={{
                  borderRadius: '16px',
                  textTransform: 'none',
                }}>
                Adicionar Novo Usuário
              </Button>
            </Grid>
          </Grid>
        )}

        {tabValue === 1 && (
          <Grid container direction="column" spacing={2}
            sx={{
              pl: 4,
              pr: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start', // Align items to the start (top)
              width: 400,
              ml: 0,
              height: '100%',
            }}
          >

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3, }} >
              <Autocomplete
                size="small"
                options={userList}
                getOptionLabel={(option) => option?.nome ?? ""}
                value={selectedUser}
                onChange={(event, newValue) => {
                  handleAutocompleteOnChangeSelectUser(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Selecione o Usuário" fullWidth />
                )}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <Autocomplete
                size="small"
                options={grupos}
                getOptionLabel={(option) => option?.nome ?? ""}
                value={editUserGrupo}
                onChange={(event, newValue) => {
                  setEditUserGrupo(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Selecione o Grupo" fullWidth />
                )}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <TextField
                label="Editar Nome"
                fullWidth
                size="small"
                value={editUserName}
                onChange={(e) => setEditUserName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <TextField
                label="Editar Email"
                fullWidth
                size="small"
                value={editUserEmail}
                onChange={(e) => setEditUserEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <InputMask
                mask="+55 (99) 99999-9999"
                value={editUserPhone}
                onChange={(e) => setEditUserPhone(e.target.value)}
                disabled={false}
                maskChar=" "
              >
                {() => (
                  <TextField
                    label="Editar Telefone"
                    fullWidth
                    size="small"
                    value={editUserPhone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3 }} >
              <TextField
                label="Senha"
                type={showPassword1 ? "text" : "password"} // Toggle type based on showPassword state
                fullWidth
                size="small"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3, }} >
              <TextField
                label="Confirmar Senha"
                type={showPassword1 ? "text" : "password"} // Toggle type based on showPassword state
                fullWidth
                size="small"
                value={userPassword2}
                onChange={(e) => setUserPassword2(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sx={{ width: '100%', paddingLeft: 3, paddingRight: 3, mt: -1 }} >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword1}
                    onChange={handlePasswordVisibility}
                  />
                }
                label="Mostrar Senha"
              />
            </Grid>

            <Grid item sx={{ width: '75%', paddingLeft: 3, paddingRight: 3, }} >
              <Button
                variant="contained"
                size="small"
                fullWidth
                disabled={isDisableEdit}
                onClick={handleEditUser}
                sx={{
                  borderRadius: '16px',
                  textTransform: 'none',
                }}>
                Salvar Edições
              </Button>
            </Grid>

            <Grid item sx={{ width: '75%', paddingLeft: 3, paddingRight: 3, mt: -1 }} >
              <Button
                variant="contained"
                size="small"
                fullWidth
                disabled={isDisableEdit}
                onClick={handleRemoveUser}
                sx={{
                  borderRadius: '16px',
                  textTransform: 'none',
                  bgcolor: 'error.main',
                  color: 'common.white',
                  '&:hover': {
                    bgcolor: 'error.dark',
                  },
                  '&:disabled': {
                    bgcolor: 'action.disabledBackground',
                    color: 'action.disabled',
                  }
                }}>
                Remover Usuário
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box >
  );
}
