import { Snackbar, Alert } from '@mui/material';
import Slide from '@mui/material/Slide';


export default function AlertSnackbar({
    open,
    handleClose,
    message,
    severity = 'error',
    verticalAnchor = 'top',
    horizontalAnchor = 'center',
    slideDirection = 'up'
}) {
    return (
        <Snackbar TransitionComponent={(props) => <Slide {...props} direction={slideDirection} />} anchorOrigin={{ vertical: verticalAnchor, horizontal: horizontalAnchor }} open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}