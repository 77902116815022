import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // esse utiliza o localStorage para armazenar os estados
import themeSlice from './themeSlice';
import devSlice from './devSlice';
import pageSlice from './pageSlice';
import notificationSlice from './notificationSlice';
import dateFilterSlice from './dateFilterSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['theme'], // estados que devem ser persistidos
}

const rootReducer = combineReducers({
    theme: themeSlice,
    dev: devSlice,
    page: pageSlice,
    notification: notificationSlice,
    dateFilterSlice:dateFilterSlice
    // outros reducers aqui
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
