import Switch from '@mui/material/Switch';
import SunIcon from '@mui/icons-material/Brightness5Rounded';
import MoonIcon from '@mui/icons-material/DarkModeOutlined';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toggleTheme } from "../redux/themeSlice";
import { useSelector, useDispatch } from 'react-redux';

const ChaveSwitch = styled((props) => (
    <Switch size='small' focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 48,
    height: 28,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '650ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#696969' : '#696969',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },

    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 30 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const RapidSwitch = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.theme.darkMode);

    const handleToggleTheme = () => {
        dispatch(toggleTheme());
    };

    return (
        <FormControlLabel
            control={
                <ChaveSwitch
                    checked={darkMode}
                    onChange={handleToggleTheme}
                    sx={{ m: 3 }}
                    icon={<SunIcon
                        sx={{
                            color: '#fff',
                            borderRadius: '50%',
                            backgroundColor: '#ffc200',
                        }}
                    />}
                    checkedIcon={< MoonIcon
                        sx={{
                            backgroundColor: 'black',
                            borderRadius: '50%',
                            color: 'white',
                            transform: 'scaleX(-1)'
                        }}
                    />}
                />}
        />
    )
}
export default RapidSwitch;