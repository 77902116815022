import api from "../services/api"
import { getUserToken } from "../utils/utils"
import { useState } from "react"
import { Box, Button, TextField, Dialog, DialogTitle, DialogContent, Divider, Typography, List, ListItemButton, ListItemText } from "@mui/material"
import IconPicker from '../modulos/IconPicker';
import React from "react";
import * as MuiIcons from '@mui/icons-material';
import { useSelector } from 'react-redux';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

export default function DevToolsCriarPagina() {
    const [pageName, setPageName] = useState('')
    const [selectedIconName, setSelectedIconName] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const pages = useSelector((state) => state.page.pages)

    const handleSavePage = async () => {
        try {
            const response = await api.post('dev_tools/criar_pagina', { nome: pageName, icone: selectedIconName }, { headers: { Authorization: getUserToken() } })
            if (response.status === 200) {
                alert(`Página '${pageName}' criada com sucesso!`, 'success')
                window.location.reload()
            }
        } catch (error) {
            if (error.response) {
                console.log(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`)
                alert(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`, 'error')
            } else if (error.request) {
                console.log("Erro de rede ou servidor não respondeu")
            } else {
                console.log("Erro ao configurar a requisição")
            }
        }
    }

    const handleDeletePage = async (id_pagina) => {
        try {
            const response = await api.post('dev_tools/remover_pagina', { id_pagina }, { headers: { Authorization: getUserToken() } })
            if (response.status === 200) {
                alert(`Página com o id '${id_pagina}' deletada com sucesso!`, 'success')
                window.location.reload()
            }
        } catch (error) {
            if (error.response) {
                console.log(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`)
                alert(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`, 'error')
            } else if (error.request) {
                console.log("Erro de rede ou servidor não respondeu")
            } else {
                console.log("Erro ao configurar a requisição")
            }
        }
    }

    const SelectedIcon = selectedIconName ? MuiIcons[selectedIconName] : null

    const handlePickIcon = () => {
        setIsDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setIsDialogOpen(false)
    }

    const handleIconSelect = iconName => {
        setSelectedIconName(iconName)
    }

    return (
        <Box>
            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Selecione o Ícone</DialogTitle>
                <DialogContent>
                    <IconPicker onIconSelect={handleIconSelect} />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <Button size='small' color='primary' variant="outlined" onClick={handleCloseDialog}>Salvar</Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box sx={{ display: 'flex' }}>
                    <TextField size="small" label="Nome da Página" onChange={(event) => setPageName(event.target.value)} />
                    <Button size='small' color='primary' variant="outlined" onClick={handlePickIcon}> Adicionar Ícone</Button>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid gray', p: 1, mx: 1, borderRadius: 1, height: 40, width: 40 }}>
                    {SelectedIcon && <SelectedIcon />}
                </Box>
                <Button size='small' color='primary' variant="contained" onClick={handleSavePage}>Criar Pagina</Button>
            </Box>

            <Box>
                <Typography variant="subtitle1">Lista de Paginas</Typography>
                <List component="nav" aria-label="mailbox folders">
                    {pages.map((page, index) => (
                        <React.Fragment key={index}>
                            <Box display='flex' flexDirection='row' alignItems='center'>
                                <ListItemButton>
                                    <ListItemText primary={page.nome_pagina} />
                                </ListItemButton>
                                <DeleteForeverRoundedIcon
                                    onClick={() => handleDeletePage(page.id)}
                                    sx={{ width: 40, cursor: 'pointer', ":hover": { color: 'red' } }} />
                            </Box>

                            {index < pages.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        </Box>
    )
}