// import { Box, Grid, TextField, Button, Typography, useTheme, InputAdornment } from '@mui/material';

// const theme = useTheme();

export const boxloginBorder = (theme, isSmallScreen) => ({
    width: 410,
    border: isSmallScreen ? '2px solid red' : 'none',

    // border: '1px solid red',
    p: 3,
    borderRadius: 2,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'
});

export const gridStyle = {
    justfyItens: 'center'
};

export const body2style =  (theme) => ({
    m: 2,
    color: theme.palette.primary.main
});

export const textStyle = {
    '& .MuiOutlinedInput-root': {borderRadius: '20px'},
};

export const gridStyle2 = {
    mb: 1,
};

export const buttonStyle = {
    borderRadius: '20px',
    textTransform: 'none',
};

export const recupStyle = {
    mt: 2 
};
