import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { useSelector } from 'react-redux'

const useGlobalTheme = () => {
    const darkMode = useSelector((state) => state.theme.darkMode)

    // nossa paleta do modo escuro
    const customDarkPalette = {
        // backgrounds
        EQ_Login_TextField: '#40474C',
        EQ_User_Saudation_Background: '#484848',
        EQ_Background_Home: '#2F363C',
        EQ_Background_Dark: '#1D272F',
        EQ_BottomTab_Background: '#404A54',

        // navigators
        EQ_Stack_Arrow: '#3C3C3C',

        // icones
        EQ_Icons: 'white',
        EQ_Icones_Background: '#1178BB',

        // background de botões
        EQ_Botão_Confirma: '#3CB371',
        EQ_Cancel_Button: '#DB5A43',

        // fontes
        EQ_Font_Light: '#FAFAFA',
        EQ_Font_Medium: '#E7E7E7',
        EQ_Font_Dark: '#C6C6C6',
        EQ_Font_Extra_Dark: 'gray',
        EQ_Font_Link: '#767676',
        EQ_Font_Hint: '#6F8098',
    }

    let lightTheme = {
        palette: {
            mode: 'light',
        },

        // fontes disponíveis: Poppins, Roboto, Lato, Open Sans
        typography: {
            fontFamily: 'Lato, sans-serif',
        },

        tableLine: {
            odd: '#FAFAFA',
            even: '#E0E0E0'
        }
    }

    let darkTheme = {
        palette: {
            mode: 'dark',
            background: { // cores customizadas do modo dark
                default: customDarkPalette.EQ_Background_Dark,
                paper: customDarkPalette.EQ_Background_Home,
                item: customDarkPalette.EQ_Login_TextField
            }
        },

        // fontes disponíveis: Poppins, Roboto, Lato, Open Sans
        typography: {
            fontFamily: 'Lato, sans-serif',
        },

        tableLine: {
            odd: '#303030',
            even: '#424242',
        }
    }

    function returnTheme() {
        if (darkMode) {
            return createTheme(darkTheme)
        } else {
            return createTheme(lightTheme)
        }
    }

    let theme = returnTheme()

    theme = responsiveFontSizes(theme) // deixa as fontes do typography mais responsivas

    // objeto para jogar estilos customizados
    theme.custom = {
        shadowHover: {
            backgroundColor: theme.palette.action.hover,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        },
    }
    return theme
}

export default useGlobalTheme