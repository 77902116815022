// um slice é uma coleção de reducers e actions para alguma funcionalidade
// esse slice é referente ao modo escuro / claro da aplicação

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    darkMode: true
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleTheme: state => {
            state.darkMode = !state.darkMode
        }
    }
})

export const { toggleTheme } = themeSlice.actions
export default themeSlice.reducer