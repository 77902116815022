import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import api from "../services/api";

const RecuperacaoSenha = ({ open, onClose }) => {
  const [stage, setStage] = useState(1);
  const [isDisable, setIsDisable] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const handleAlreadyHaveCode = () => {
    setStage(2);
  };

  const handleSendCode = async () => {
    //send email with code
    setIsDisable(true); // Disable botão
    try {
      const response = await api.post("/gestao/recupera_senha", {
        email: email,
      });

      if (response.status === 200) {
        alert("Codigo de alteração de senha enviado para o email");
      }
    } catch (error) {
      if (error.response) {
        alert(
          error.response.data.error ||
            "An error occurred. Please try again later."
        );
      } else {
        alert("An error occurred. Please try again later.");
      }
    } finally {
      setIsDisable(false);
      setStage(2);
    }
  };

  const handleAlteraSenha = async () => {
    setIsDisable(true);
    let response;

    try {
      if (password === password2) {
        response = await api.post("/gestao/altera_senha", {
          codigo_validacao: verificationCode,
          nova_senha: password,
        });

        if (response && response.status === 200) {
          alert("Senha Alterada com Sucesso");
          onClose();
        }
      } else {
        alert("Senhas Não Coincidem");
      }
    } catch (error) {
      console.error("Erro ao Logar:", error);
      if (error.response) {
        alert(
          error.response.data.error ||
            "Occorreu um erro, tente novamente mais tarde."
        );
      } else {
        alert("Occorreu um erro, tente novamente mais tarde.");
      }
    } finally {
      setIsDisable(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: "center" }}>
        Recuperação de Senha
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 2,
          width: 500,
          textAlign: "center",
        }}
      >
        {stage === 1 && (
          <>
            Enviaremos um código de verificação no seu email.
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              onClick={handleSendCode}
              color="primary"
              variant="contained"
              disabled={isDisable}
            >
              Enviar Email
            </Button>
            <Button variant="contained" onClick={handleAlreadyHaveCode}>
              Já possuo código
            </Button>
          </>
        )}
        {stage === 2 && (
          <>
            Digite o código de verificação e defina uma nova senha.
            <TextField
              margin="dense"
              label="Código de verificação"
              type="text"
              fullWidth
              variant="outlined"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Nova Senha"
              type="password"
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Confirme sua Nova Senha"
              type="password"
              fullWidth
              variant="outlined"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={handleAlteraSenha}
              color="primary"
              disabled={isDisable}
            >
              Salvar Nova Senha
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RecuperacaoSenha;
