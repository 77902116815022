import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from '../paginas/Dashboard';
import Login from '../paginas/Login';
import AppWrapper from '../componentes/AppWrapper';
import PerfilUsuario from '../paginas/PerfilUsuario';
import WelcomePage from '../paginas/WelcomePage';
import { Navigate } from 'react-router-dom';
import { authentication } from '../utils/utils';

const PrivateRoute = ({ children }) => {
    const userIsAuthenticated = authentication()
    return userIsAuthenticated ? children : <Navigate to='/login' />
}

export default function Rotas() {
    return (
        <BrowserRouter>
            <AppWrapper>
                <Routes>
                    <Route path="/login" element={<Login />} />

                    <Route path="/" element={<PrivateRoute><WelcomePage /></PrivateRoute>} />
                    <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                    <Route path="/usuario" element={<PrivateRoute><PerfilUsuario /></PrivateRoute>} />
                </Routes>
            </AppWrapper>
        </BrowserRouter>
    )
}