import React, { useState, useEffect, useRef } from 'react';
import api from '../services/api';
import { getUserToken } from '../utils/utils';
import { DataGrid, GridToolbar, ptBR } from '@mui/x-data-grid';
import ColunaSoma from '../componentes/ColunaSoma';
import * as XLSX from 'xlsx';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import useGlobalTheme from '../estilos/useGlobalTheme';
import { useSelector } from 'react-redux';

const ModuloTabela = ({ params }) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const gridKeyRef = useRef(0);
  const apiRef = useRef(null);
  const theme = useGlobalTheme();
  const startDate = useSelector(state => state.dateFilterSlice.dataIni);
  const endDate = useSelector(state => state.dateFilterSlice.dataFim);
  const [isRentabilidadeCall, setIsRentabilidadeCall] = useState(false);

  useEffect(() => {
    const fetchApiCall = async () => {
      try {
        const urlWithParams = `${params.apiEndpoint}?data_ini=${encodeURIComponent(startDate)}&data_fim=${encodeURIComponent(endDate)}`;
        const response = await api.get(urlWithParams, {
          headers: { 'Authorization': getUserToken() }
        });

        let responseData = response.data;
        if (typeof responseData === 'string') {
          try {
            responseData = JSON.parse(responseData);
          } catch (error) {
            console.error('Erro ao converter responseData para JSON:', error);
          }
        }

        if (response.status === 200 && responseData) {
          const filteredColumns = responseData.columns.filter(column => column.field !== 'id');
          setRows(responseData.rows);
          setColumns(filteredColumns);
          gridKeyRef.current += 1;

          if (params.apiEndpoint.includes('retorna_rentabilidade_completo_por_intervalo')) {
            setIsRentabilidadeCall(true);
          } else {
            setIsRentabilidadeCall(false);
          }
        } else {
          console.error("Failed to fetch data, status code:", response.status);
        }
      } catch (error) {
        console.error('Erro ao obter páginas:', error);
      }
    };

    fetchApiCall();
  }, [startDate, endDate, params.apiEndpoint]);

  const compareDates = (v1, v2) => {
    const dateParts1 = v1.split('/').map(Number);
    const dateParts2 = v2.split('/').map(Number);
    const date1 = new Date(dateParts1[2], dateParts1[1] - 1, dateParts1[0]);
    const date2 = new Date(dateParts2[2], dateParts2[1] - 1, dateParts2[0]);
    return date1 - date2;
  };

  const formattedColumns = columns.map((column) => {
    if (column.isDate) {
      return {
        ...column,
        sortComparator: (v1, v2, param1, param2) => compareDates(param1.api.getCellValue(param1.id, column.field), param2.api.getCellValue(param2.id, column.field))
      };
    }
    if (column.field === 'nomfor_total_open') {
      return {
        ...column,
        renderCell: (params) => (
          <div style={{ whiteSpace: 'pre-line', fontSize: '12px' }}>
            {params.value}
          </div>
        ),
      };
    }
    if (column.field === 'historico_valores') {
      return {
        ...column,
        renderCell: (e) => <GridSparklineCell {...e} />,
        valueGetter: (e) => e.row.historico_valores,
      };
    }
    if (column.isFormatBR) {
      return {
        ...column,
        renderCell: (params) => (
          <div>
            {params.value !== null && params.value !== undefined && params.value !== '-'
              ? Number(params.value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              : '-'}
          </div>
        ),
      };
    }
    return column;
  });

  function GridSparklineCell(props) {
    if (!Array.isArray(props.value)) {
      return <div style={{ color: 'red' }}>Error</div>;
    }

    if (props.value.length > 0) {
      return (
        <SparkLineChart
          data={props.value}
          width={props.colDef.computedWidth}
          plotType={props.plotType}
        />
      );
    }
  }

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const formattedRow = {};
      columns.forEach(column => {
        formattedRow[column.headerName] = row[column.field];
      });
      return formattedRow;
    }));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, `${params.apiEndpoint.replace(/\//g, '_')}.xlsx`);
  };

  const getRowStyles = () => {
    return {
      '& .MuiDataGrid-row:nth-of-type(odd)': {
        backgroundColor: theme.tableLine.odd,
      },
      '& .MuiDataGrid-row:nth-of-type(even)': {
        backgroundColor: theme.tableLine.even,
      },
    };
  };

  const CustomToolbar = () => (
    <div style={{ display: 'flex', width: '100%' }}>
      <Button onClick={exportToExcel} style={{ margin: '0 20px', width: 100 }}>
        Baixar Excel
      </Button>
      <GridToolbar />
    </div>
  );

  const saveEdit = async (newRow) => {
    try {
      await api.post(params.edicaoEndpoint, newRow, { headers: { Authorization: getUserToken() } });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          alert("Por favor, insira um valor válido.");
        } else {
          console.log(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`);
        }
      } else if (error.request) {
        console.log("Erro de rede ou servidor não respondeu");
      } else {
        console.log("Erro ao configurar a requisição");
      }
    }
  };

  const handleRowClick = async (params) => {
    if (!isRentabilidadeCall) {
      return;
    }
    
    try {
      const response = await api.get('/relatorios/retorna_detalhes_pedido', {
        params: {
          numero_pedido: params.row.numped,
          nome_vendedor: params.row.nomrep,
          produto :params.row.despro
        },
        headers: { 'Authorization': getUserToken() }
      });

      setOrderDetails(response.data);
      setSelectedRow(params.row);
      setOpen(true);
    } catch (error) {
      console.error('Erro ao obter detalhes do pedido:', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
    setOrderDetails(null);
  };

  return (
    <div style={{ width: '100%', maxWidth: '100%' }}>
      {params.tabelaSoma && <ColunaSoma rows={rows} columns={columns} apiRef={apiRef} filterModel={filterModel} />}
      <DataGrid
        localeText={{
          ...ptBR.components.MuiDataGrid.defaultProps.localeText,
          footerRowSelected: (count) => ''
        }}
        density='compact'
        key={gridKeyRef.current}
        rows={rows}
        columns={formattedColumns.map(column => ({
          ...column,
        }))}
        getRowHeight={() => 'auto'}
        pageSize={5}
        isCellEditable={() => Boolean(params.tabelaEditavel)}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection={false}
        apiRef={apiRef}
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        onFilterModelChange={setFilterModel}
        sx={getRowStyles()}
        processRowUpdate={newRow => {
          if (params.tabelaEditavel) {
            saveEdit(newRow);
          }
          return newRow;
        }}
        onProcessRowUpdateError={error => console.log(error)}
        onRowClick={handleRowClick}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle>Detalhes do Pedido</DialogTitle>
        <DialogContent>
          {orderDetails ? (
            <div>
              <Typography variant="body1"><strong>Nome Vendedor:</strong> {orderDetails.nomrep}</Typography>
              <Typography variant="body1"><strong>Cliente:</strong> {orderDetails.nomcli}</Typography>
              <Typography variant="body1"><strong>Data de Emissão:</strong> {orderDetails.datemi}</Typography>
              <Typography variant="body1"><strong>Número do Pedido:</strong> {orderDetails.numped}</Typography>
              <Typography variant="body1"><strong>Produtos:</strong></Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Produto</TableCell>
                      <TableCell>Quantidade</TableCell>
                      <TableCell>Embalagem</TableCell>
                      <TableCell>MB</TableCell>
                      <TableCell>Tipo Pedido</TableCell>
                      <TableCell>Comissão</TableCell>
                      <TableCell>Vencimento</TableCell>
                      <TableCell>Local de Carregamento</TableCell>
                      <TableCell>Mês de Embarque</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{orderDetails.despro}</TableCell>
                      <TableCell>{orderDetails.qtdped}</TableCell>
                      <TableCell>{orderDetails.usu_tipemb}</TableCell>
                      <TableCell>{orderDetails.mb}</TableCell>
                      <TableCell>{orderDetails.usu_destip}</TableCell>
                      <TableCell>{orderDetails.comissao || '-'}</TableCell>
                      <TableCell>{orderDetails.vencimento || '-'}</TableCell>
                      <TableCell>{orderDetails.local_carregamento || '-'}</TableCell>
                      <TableCell>{orderDetails.mes_embarque || '-'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <Typography variant="body1">Carregando...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModuloTabela;
