const carrosselStyle = {

  container: {
    display: "flex",
    width: "100%", // Largura máxima da Box
    height: "100%",
    flexDirection: "column",
  },

  titulo: {
    fontFamily: 'Poppins, sans-serif',
    textAlign: "center",
  },

  conteudo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

imagePlusTilte:{
  border: "1px solid grey",
  borderRadius: 2,
  mt: 0.7,
},

imageBox: {
    width: "100%", // Largura máxima da Box
    height: "83vh", // Altura máxima da Box, ajuste conforme necessário
    overflow: "hidden", // Esconde qualquer conteúdo que ultrapasse a Box
    display: "flex", // Usa flexbox para alinhar a imagem
    justifyContent: "center", // Centraliza horizontalmente
    alignItems: "center",
  },

  sliderBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    px: 2,
    borderRadius: 2,
    my: 1,
  },

  ihc: {
    width: "100%",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },

  interface: {
    height: '65px',
    width: "100%",
    borderRadius: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },

  playerBox: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    p: 1,
  },

  lequeBox: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: 1,
  },

  rangeGif: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    p: 1,
  },

  flipBox: {
    borderRadius: 2,
    width: 35,
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mx: 0.5,
  },
  
};

export default carrosselStyle;
