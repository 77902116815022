import React, { useState, useEffect, useMemo } from 'react';
import { TextField, Grid, Paper, Box } from '@mui/material';
import * as MuiIcons from '@mui/icons-material';

const IconsDisplay = React.memo(({ icons, onSelect, selectedIcon }) => {

    const columns = 6;
    const rows = Math.ceil(icons.length / columns);
    const lastRowItemCount = icons.length % columns;
    const isLastRowIncomplete = lastRowItemCount !== 0;
    const lastRowItemSize = isLastRowIncomplete ? Math.floor(12 / lastRowItemCount) : 2;

    return (
        <Grid container spacing={0.6}>
            {icons.map((iconName, index) => {
                const isLastRow = index >= (rows - 1) * columns;
                const IconComponent = MuiIcons[iconName];
                return (
                    <Grid
                        item
                        key={index}
                        xs={isLastRow && isLastRowIncomplete && index >= icons.length - lastRowItemCount ? lastRowItemSize : 2}
                    >
                        <Paper
                            sx={{
                                padding: '10px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                height: '100%',
                                backgroundColor: iconName === selectedIcon ? 'rgba(0, 0, 0, 0.08)' : '',
                            }}
                            onClick={() => onSelect(iconName)}
                        >
                            <IconComponent />
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    );
});

const IconPicker = ({ onIconSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const iconsPerPage = 20;

    const iconNames = useMemo(() => Object.keys(MuiIcons).filter(name => name !== 'default'), []);

    const filteredIcons = useMemo(() => {
        return searchTerm
            ? iconNames.filter(name => name.toLowerCase().includes(searchTerm.toLowerCase()))
            : iconNames;
    }, [searchTerm, iconNames]);

    const paginatedIcons = useMemo(() => {
        const startIndex = currentPage * iconsPerPage;
        return filteredIcons.slice(startIndex, startIndex + iconsPerPage);
    }, [currentPage, filteredIcons]);

    const handleIconSelect = iconName => {
        setSelectedIcon(iconName);
        if (onIconSelect) {
            onIconSelect(iconName);
        }
    };

    // Display the selected icon
    const SelectedIconComponent = selectedIcon ? MuiIcons[selectedIcon] : null;

    return (
        <Grid container sx={{ p: 2, }} justifyContent="center" alignItems="center">
            <Grid item xs={12} display="flex" justifyContent="center">
                <TextField
                    label="Search Icon"
                    size='small'
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: 300, mb: 2, }}
                />
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="center">
                <IconsDisplay icons={paginatedIcons} onSelect={handleIconSelect} selectedIcon={selectedIcon} />
            </Grid>
        </Grid>
    );
};


export default IconPicker;
