import ResponsiveGrid from "../responsiveGrid/ResponsiveGrid"
import { useState, useCallback } from "react"
import { useEffect } from "react"
import { useSelector } from 'react-redux';
import { Box } from "@mui/material"
import DevTools from "../devTools/DevTools";

export default function Dashboard() {
    // importante o layout ser um state para a memoization do grid funcionar
    // ou ele ficar de fora do componente como uma variável estática
    const devMode = useSelector((state) => state.dev.devMode)

    const currentPage = useSelector((state) => state.page.currentPage)
    const currentPageId = useSelector((state) => state.page.currentPage.id)
    const [layouts, setLayouts] = useState({ lg: [] })

    useEffect(() => {
        if (currentPage && currentPage.layouts) {
            setLayouts(currentPage.layouts)
        } else if (currentPage && !currentPage.layouts) {
            setLayouts({ lg: [] })
        }
    }, [currentPage])

    const handleSetNewLayout = useCallback((newLayout) => {
        setLayouts(newLayout)
    }, [])

    return (
        <Box>
            <ResponsiveGrid
                layouts={layouts}
                devMode={devMode}
                onLayoutChange={handleSetNewLayout}
            />

            {devMode && <DevTools
                layouts={layouts}
                onLayoutChange={handleSetNewLayout}
                currentPageId={currentPageId}
            />}
        </Box>)
}