import React from 'react';
import ComponenteCaixaLogin from '../componentes/ComponenteCaixaLogin';
import { useSelector} from 'react-redux';
import { Box, ThemeProvider, createTheme, Grid } from '@mui/material';
// Imagens
import NighttimeBackground from '../assets/images/milhoNoite.png';
import daytimeBackground from '../assets/images/milhoDia.png';
// Estilos
import { screenStyle, boxImage, boxLogin, maskBoxStyle } from '../estilos/loginStyle';
//Switch de tema
import RapidSwitch from '../componentes/ChaveTema'

export default function Login() {
    const darkMode = useSelector((state) => state.theme.darkMode);
    const theme = createTheme({
        palette: { mode: darkMode ? 'dark' : 'light' },
    });

    return (
        <ThemeProvider theme={theme}>
            <Box sx={screenStyle}>
                <Grid container>
                    <Grid item xs={12} md={8} sx={boxImage}>
                        <Box sx={maskBoxStyle(darkMode, daytimeBackground, NighttimeBackground)} />
                    </Grid>
                    <Grid item xs={12} md={4} sx={boxLogin}>
                        <ComponenteCaixaLogin />
                        <Box>
                            Alterar Tema: <RapidSwitch/>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}