import React, { useState, useEffect } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Box, TextField } from '@mui/material';
import api from '../services/api';
import { getUserToken } from '../utils/utils';
import testeClima1 from '../assets/images/TESTE1.png';
import testeClima2 from '../assets/images/TESTE2.png';

const ModuloPrevisaoClimaticaComparada = ({ params }) => {
    const [compareDate1, setCompareDate1] = useState('');
    const [compareDate2, setCompareDate2] = useState('');

    const minDate = '2023-01-01';
    const maxDate = '2024-12-31';

    useEffect(() => {
        fetchApiCall();
    }, [params]);

    const fetchApiCall = async () => {
        const endpoint = params ? params : null;
        try {
            const response = await api.get(endpoint, {
                headers: { 'Authorization': getUserToken() }
            });
            if (response.status === 200 && response.data) {
                // console.log não é tratamento de resposta >:(
            } else {
                console.error(`Failed to fetch data, status code: ${response.status}`);
            }
        } catch (error) {
            console.error('Erro ao obter dados: ' + error.message);
        }
    };

    return (
        <Box sx={{ height: '100%', padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ overflow: 'hidden', border: '1px solid black' }}>

                <ReactCompareSlider
                    itemOne={
                        <ReactCompareSliderImage
                            src={testeClima1}
                            alt="Clima Antes"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                    }
                    itemTwo={
                        <ReactCompareSliderImage
                            src={testeClima2}
                            alt="Clima Depois"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                    }
                    style={{ width: '100%', height: '100%' }}
                />
            </Box>

            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
                <TextField
                    id="compare-date-1"
                    label="Data inicial"
                    type="date"
                    defaultValue={minDate}
                    sx={{ marginRight: 1 }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: minDate, max: maxDate }}
                    onChange={(e) => setCompareDate1(e.target.value)}
                />

                <TextField
                    id="compare-date-2"
                    label="Data Final"
                    type="date"
                    defaultValue={minDate}
                    sx={{ marginRight: 1 }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: minDate, max: maxDate }}
                    onChange={(e) => setCompareDate2(e.target.value)}
                />
            </Box>
        </Box >
    );
};

export default ModuloPrevisaoClimaticaComparada;
