import { jwtDecode } from "jwt-decode";

const devEmail = 'dev@dev.com'

function getUserToken() {
    const token = localStorage.getItem('token')

    if (token) {
        return token
    } else {
        return null
    }
}

function getUserInfo() {
    const usuario = localStorage.getItem('usuario')

    if (usuario) {
        return JSON.parse(usuario)
    } else {
        return null
    }
}

function isDev() {
    try {
        return Boolean(jwtDecode(getUserToken()).email === devEmail)
    } catch {
        return false
    }
}

function authentication() {
    try {
        const token = getUserToken()
        if (token) {
            const decodedToken = jwtDecode(token)
            const userInfo = getUserInfo()

            if (userInfo.email === decodedToken.email) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    } catch {
        localStorage.clear()
        return false
    }
}

export {
    getUserToken,
    isDev,
    getUserInfo,
    authentication
}
