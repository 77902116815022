import React, { useRef, useEffect, useState } from 'react';
import { Popover, List, ListItem, Divider, Typography, ButtonBase, Box, Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import { useSelector } from 'react-redux';
import { getUserToken } from '../utils/utils';
import api from '../services/api';
import { setNotifications } from '../redux/notificationSlice';
import { useDispatch } from 'react-redux';

const NotificationPopup = ({ open, notificationElement, onClose }) => {
    const id = open ? 'Notificações Popup' : undefined
    const theme = useTheme()
    const dispatch = useDispatch()

    const notifications = useSelector(state => state.notification.notifications)

    const [openTooltip, setOpenTooltip] = useState(null)

    const handleTooltipOpen = (index) => {
        setOpenTooltip(index)
    }

    const handleTooltipClose = () => {
        setOpenTooltip(null)
    }

    const marcarComoLido = async (id_notificacao) => {
        try {
            handleTooltipClose()
            var response = await api.post('gestao/atualiza_status_notificacao', { id_notificacao }, { headers: { Authorization: getUserToken() } })
            if (response.status === 200) {
                await retornaNotifications()
            }
        } catch (error) {
            if (error.response) {
                alert(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`)
            } else if (error.request) {
                alert("Erro de rede ou servidor não respondeu")
            } else {
                alert("Erro ao configurar a requisição")
            }
        }
    }

    const retornaNotifications = async () => {
        try {
            var response = await api.get('gestao/retornar_notificacoes_usuario', { headers: { Authorization: getUserToken() } })
            dispatch(setNotifications(response.data.notificacoes))

        } catch (error) {
            if (error.response) {
                alert(`Erro no servidor, ${error.response.status}: ${error.response.data.erro}`)
            } else if (error.request) {
                alert("Erro de rede ou servidor não respondeu")
            } else {
                alert("Erro ao configurar a requisição")
            }
        }
    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={notificationElement}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <List sx={{ width: 360, bgcolor: 'background.paper' }}>
                {notifications.length === 0 ?
                    <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography sx={{ color: 'text.secondary' }}>
                            Não existem notificações, tudo limpo!
                        </Typography>
                    </ListItem>
                    : null}

                {notifications.map((notification, index) => (
                    <React.Fragment key={index}>
                        <Tooltip title='Clique para marcar como lido' placement='right' disableHoverListener={Boolean(notification.lida)} open={openTooltip === index}
                            onClose={handleTooltipClose}
                            onOpen={() => handleTooltipOpen(index)}>
                            <ListItem
                                onClick={() => notification.lida === 0 ? marcarComoLido(notification.id) : null}
                                sx={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', '&:hover': theme.custom.shadowHover, alignItems: 'start' }}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', }}>
                                    <Box sx={{ alignItems: 'center', display: 'flex', width: '75%', textAlign: 'left', }}>
                                        <OverflowTooltip text={notification.titulo} lida={notification.lida} />
                                    </Box>
                                    <Box sx={{ alignItems: 'center', display: 'flex', width: '20%', }}>
                                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                            {new Date(notification.criado_em).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="subtitle1" sx={{ color: notification.lida === 1 ? 'darkgray' : null }}>{notification.mensagem}</Typography>
                                </Box>
                            </ListItem>
                            {index < notifications.length - 1 && <Divider />}
                        </Tooltip>
                    </React.Fragment>
                ))}
            </List>
        </Popover>
    )
}

const OverflowTooltip = ({ text, lida }) => {
    const [isOverflowed, setIsOverflowed] = useState(false)
    const textRef = useRef(null)

    useEffect(() => {
        if (textRef.current) {
            setIsOverflowed(textRef.current.scrollWidth > textRef.current.clientWidth);
        }
    }, [text])

    // esse componente retorna uma tooltip para o titulo caso ele seja maior do que a div (overflow)
    return (
        <Tooltip title={text} disableHoverListener={!isOverflowed} TransitionComponent={Zoom}>
            <Typography
                variant="caption"
                ref={textRef}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: lida === 1 ? 'darkgray' : null }}
            >
                {text}
            </Typography>
        </Tooltip>
    )
}


export default NotificationPopup
