import React from 'react'
import { Typography, Button, Paper, Grid, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import BarChartIcon from '@mui/icons-material/BarChart'
import { setCurrentPage } from '../redux/pageSlice'
import { getUserInfo } from '../utils/utils'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { isDev } from '../utils/utils';
import DevToolsCriarPagina from '../devTools/DevToolsCriarPagina'

const useUserInfo = () => {
    return getUserInfo().nome
}

const useResponsiveLayout = () => {
    return useMediaQuery('(min-width:600px)')
}

const WelcomePage = () => {
    const pages = useSelector((state) => state.page.pages)
    const dispatch = useDispatch()
    const userName = useUserInfo()
    const matches = useResponsiveLayout()
    const navigate = useNavigate()

    const handleGoToDashboard = (page) => {
        // o caminho /dashboard renderiza a página que foi selecionada com o setCurrentPage
        // por isso tem que selecionar a pagina e depois mandar pro caminho certo, pra ser renderizado
        dispatch(setCurrentPage(page))
        navigate('/dashboard')
    }

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Box sx={{ borderRadius: 1, padding: 2, }} component={Paper}>
                        <StyledTypography>
                            Bem vindo(a), {userName}!
                        </StyledTypography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ borderRadius: 1, padding: 1, width: 'fit-content', marginBottom: 1 }} component={Paper}>
                        <StyledTypography variant='subtitle2'>
                            Sua lista de Dashboards
                        </StyledTypography>
                    </Box>

                    <Grid container component={Paper} sx={{ padding: 2 }}>
                        {pages.length === 0 ?
                            <Typography>
                                Nenhum dashboard disponível.
                            </Typography>
                            : pages.map((page, index) =>
                                <Grid item xs={12} sm={5} md={3} lg={2} key={index}
                                    display='flex'
                                    flexDirection='row'
                                    alignItems='center'
                                    onClick={() => handleGoToDashboard(page)}
                                    sx={{
                                        padding: 1, marginRight: matches ? 2 : 0, marginBottom: 1, marginTop: 1, borderRadius: 1,
                                        backgroundColor: 'background.item', cursor: 'pointer', boxShadow: 2,
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            transition: 'transform 0.1s ease-out',
                                        },
                                    }}>
                                    <StyledBox>
                                        <BarChartIcon />
                                    </StyledBox>

                                    <StyledTypography>
                                        {page.nome_pagina}
                                    </StyledTypography>
                                </Grid>
                            )}
                    </Grid>
                </Grid>

                {isDev() &&
                    <Grid item xs={12}>
                        <Box sx={{ borderRadius: 1, padding: 1, width: 'fit-content', marginBottom: 1 }} component={Paper}>
                            <StyledTypography variant='subtitle2'>
                                Sessão de DevTools
                            </StyledTypography>
                        </Box>

                        <Box sx={{ borderRadius: 1, padding: 2, }} component={Paper}>
                            <StyledTypography variant='subtitle1'>
                                Criação de Página
                            </StyledTypography>

                            <Box sx={{ mt: 2 }}>
                                <DevToolsCriarPagina />
                            </Box>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}

const StyledTypography = styled(Typography)(({ theme }) => ({
    whiteSpace: 'nowrap', // mantem o texto em uma linha
    overflow: 'hidden',   // esconde o que sai pra fora
    textOverflow: 'ellipsis', // renderiza 3 pontinhos pro texto que cortou
}))

const StyledBox = styled('Box')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: 30,
    height: 30,
    minWidth: 30,
    minHeight: 30,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

export default WelcomePage
