// Máscara da Imagem
import loginMask from '../assets/images/LoginMask.svg'

export const screenStyle = {
    flexDirection: 'row',
    display: 'flex',
    height: '100%',
    width: '100%',
};

export const boxImage = {
    paddingBottom: 4,
    paddingTop: 4,
    height: 'auto',
};

export const maskBoxStyle = (darkMode, daytimeBackground, NighttimeBackground) => ({
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundImage: `url(${darkMode ? NighttimeBackground : daytimeBackground})`,
    backgroundSize: 'cover',
    maskImage: `url(${loginMask})`,
    maskPosition: 'right',
    maskSize: 'cover',
    transition: 'background-image 1s ease-in-out',
});

export const boxLogin = {
    justifyContent: 'center',
    flexDirection: 'column', 
    alignItems: 'center',
    display: 'flex', 
    height: '100%',
    top: '50%',
};