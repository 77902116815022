import axios from 'axios';
let link = window.location.href
let url = ''
if (link.includes('http://localhost:3000/')) {
  url = 'http://localhost:5000/'
}
else {
  url = 'https://back.digitalequilibrio.com:5000/'
}

const api = axios.create({
  baseURL: url,

});

export default api;
