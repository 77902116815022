import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function ParamDialog({ open, handleClose, componentType, onSaveComponent }) {
    const [keyValue, setKeyValue] = useState('')
    const [value, setValue] = useState('')
    const [params, setParams] = useState({})

    const handleAddParam = () => {
        if (keyValue && value) {
            setParams(prev => ({ ...prev, [keyValue]: value }));
            setKeyValue('');
            setValue('');
        }
    }

    const handleSave = () => {
        onSaveComponent(Object.keys(params).length > 0 ? params : {})
        clearOnClose()
    }

    const clearOnClose = () => {
        setKeyValue('')
        setValue('')
        setParams({})
        handleClose()
    }

    return (
        <Dialog open={open} onClose={clearOnClose}>
            <DialogTitle>Adicionar Parâmetros para '{componentType}'</DialogTitle>
            <DialogContent>
                <TextField
                    key={1}
                    size='small'
                    autoFocus
                    margin="dense"
                    label="Chave"
                    type="text"
                    fullWidth
                    value={keyValue}
                    onChange={(e) => setKeyValue(e.target.value)}
                />
                <TextField
                    key={2}
                    size='small'
                    margin="dense"
                    label="Valor"
                    type="text"
                    fullWidth
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
                <Button size='small' onClick={handleAddParam}>Adicionar Parâmetro</Button>

                <List dense>
                    {Object.entries(params).map(([key, val], index) => (
                        <ListItem key={index} sx={{ border: '1px solid lightgray' }}>
                            <ListItemText primary={`${key}: ${val}`} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button size='small' onClick={clearOnClose}>Cancelar</Button>
                <Button size='small' onClick={handleSave}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}