import { useState, useEffect, useRef } from 'react'
import { ListItemText, Avatar, Divider, Box, ButtonBase, Badge } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { getUserInfo } from '../utils/utils'
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import NotificationPopup from './NotificationPopup';
import { useSelector } from 'react-redux';

export default function UserWidget() {
    const [userName, setUserName] = useState('')
    const navigate = useNavigate()
    const notificationElement = useRef(null)
    const [openNotifications, setOpenNotifications] = useState(false)
    const notifications = useSelector(state => state.notification.notifications)
    const [notificationAmount, setNotificationAmount] = useState(0)

    useEffect(() => {
        let name = getUserInfo().nome
        name = name.split(" ")[0]
        setUserName(name)
    }, [])

    useEffect(() => {
        const contagemNotificacoesNaoLidas = notifications.reduce((acumulador, notification) => {
            return notification.lida === 0 ? acumulador + 1 : acumulador
        }, 0)

        setNotificationAmount(contagemNotificacoesNaoLidas)
    }, [notifications])


    const handleToggle = () => {
        setOpenNotifications((currentValue) => !currentValue)
    }

    const handleClose = () => {
        setOpenNotifications(false)
    }

    return (
        <Box sx={{ flex: 1, bgcolor: 'background.paper' }} >
            <Divider />
            <Box sx={{ display: 'flex' }}>
                <UserButton
                    onClick={() => navigate('/usuario')}
                    aria-label="Sessão do Usuário">
                    <Avatar sx={{ bgcolor: 'grey.500' }}><PersonIcon /></Avatar>
                    <ListItemText primary={userName} /> {/* acabou que usar um list item text aqui resultou no estilo perfeito, então ele vai ficar ai */}
                </UserButton>
                <NotificationButton
                    ref={notificationElement}
                    onClick={handleToggle}
                    aria-label="Notificações">
                    <Badge color="error" invisible={notificationAmount === 0} badgeContent={notificationAmount}>
                        <NotificationsIcon />
                    </Badge>
                </NotificationButton>

            </Box>
            <NotificationPopup
                open={openNotifications}
                notificationElement={notificationElement.current}
                onClose={handleClose}
            />
        </Box>
    )
}

const UserButton = styled(ButtonBase)(({ theme }) => ({
    flex: 0.7,
    display: 'flex',
    padding: theme.spacing(1),
    transition: 'background-color 0.3s, box-shadow 0.3s',
    '&:hover': theme.custom.shadowHover,
}));

const NotificationButton = styled(ButtonBase)(({ theme }) => ({
    flex: 0.3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s, box-shadow 0.3s',
    '&:hover': theme.custom.shadowHover,
}));