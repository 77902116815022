import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pages: [],
    currentPage: { id: -1, layouts: { lg: [] } }
}

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setPages: (state, action) => {
            state.pages = action.payload
        },
        clearPage: (state) => {
            state.currentPage = initialState.currentPage
        }
    }
})

export const { setCurrentPage, setPages, clearPage } = pageSlice.actions
export default pageSlice.reducer