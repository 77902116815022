import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    devMode: false,
}

export const devSlice = createSlice({
    name: 'dev',
    initialState,
    reducers: {
        toggleDevMode: state => {
            state.devMode = !state.devMode
        }
    }
})

export const { toggleDevMode } = devSlice.actions
export default devSlice.reducer