import React, { useEffect, useState } from 'react';
import { gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { Box, Dialog, DialogTitle, DialogContent, Button, Paper, Typography } from '@mui/material';

const ColunaSoma = ({ rows, columns, apiRef, filterModel }) => {
    const [numericTotals, setNumericTotals] = useState({});
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        const calculateNumericTotals = () => {
            if (!apiRef.current) return;
            const filteredRowIds = gridFilteredSortedRowIdsSelector(apiRef.current.state);

            const filteredRows = filteredRowIds.map(id => rows.find(row => row.id === id));
            const newNumericTotals = {};

            if (filteredRows.length > 0) {
                columns.forEach(column => {
                    const { field, headerName } = column;
                    if (!isNaN(filteredRows[0][field]) && column.isSoma) {
                        const sum = filteredRows.reduce((total, row) => (
                            total + (parseFloat(row[field]) || 0)
                        ), 0);
                        newNumericTotals[headerName || field] = sum.toFixed(2);
                    }
                });
            }

            setNumericTotals(newNumericTotals);
        };

        calculateNumericTotals();
    }, [rows, columns, apiRef, filterModel]);

    return (
        <Box sx={{ position: 'absolute', zIndex: 2000, bottom: 0 }}>
            <Button size='small' variant="outlined" onClick={handleClickOpen} sx={{ margin: 1 }}>
                Ver Totais
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Totais das colunas</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2, // espaçamento entre cada linha de totais
                        boxSizing: 'border-box',
                        borderRadius: 1,
                    }}>
                        {Object.entries(numericTotals)
                            .map(([key, value]) => (
                                <Paper key={key} sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="subtitle2" component="span" sx={{ fontWeight: 'bold', mr: 0.7 }}>
                                        {key}:
                                    </Typography>
                                    <Typography variant="body1" component="span">
                                        {value}
                                    </Typography>
                                </Paper>
                            ))}
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
};

export default ColunaSoma;
