import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { getUserToken } from '../utils/utils';
import { Box, Tabs, Tab, Grid, TextField, FormControlLabel, Checkbox, Button, Typography } from '@mui/material';



export default function CadastroEdicaoGrupos({ params }) {

  const [grupos, setGrupos] = useState([]);
  const [selectedGrupos, setSelectedGrupos] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationContent, setNotificationContent] = useState('');
  const [isDisable, setIsDisable] = useState(false);


  useEffect(() => {
    fetchGrupos()
  }, []);

  const fetchGrupos = async () => {
    try {
      const response = await api.get('/gestao/retornar_grupos', {
        headers: { 'Authorization': getUserToken() }
      });
      if (response.status === 200) {
        setGrupos(response.data.grupos);
      }

    } catch (error) {
      const errorMessage = error.response?.data.error || 'Ocorreu um erro. Tente novamente mais tarde.';
      alert(errorMessage);
    }
  };

  const handleSendNotification = async () => {
    setIsDisable(true);
    try {
      const response = await api.post("/gestao/criar_notificacao", {
        "titulo": notificationTitle,
        "mensagem": notificationContent,
        "grupos": selectedGrupos
      }, {
        headers: { 'Authorization': getUserToken() }
      });

      if (response.status === 200) {
        alert("Notificação enviada com sucesso.");
      }
    } catch (error) {
      console.error("Erro ao enviar notificação", error);

      if (error.response) {
        alert(
          error.response.data.error ||
          "Ocorreu um erro, tente novamente mais tarde."
        );
      } else {
        alert("Ocorreu um erro, tente novamente mais tarde.");
      }
    } finally {
      setIsDisable(false);
    }
  };

  const handleCheckboxChange = (grupoId) => {
    setSelectedGrupos(prev => {
      if (prev.includes(grupoId)) {
        return prev.filter(id => id !== grupoId);
      } else {
        return [...prev, grupoId];
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedGrupos(grupos.map(grupo => grupo.id));
    } else {
      setSelectedGrupos([]);
    }
  };

  const isAllSelected = grupos.length > 0 && selectedGrupos.length === grupos.length;

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ p: 1, width: '100%' }}
      >
        <Grid
          container
          direction="column"
          item
          spacing={1}
          sx={{
            px: 4, display: 'flex', flexDirection: 'column',
            alignItems: 'center', justifyContent: 'flex-start', maxWidth: '100%', width: 400, height: '100%',
          }}
        >
          <Grid item sx={{ width: '100%', mt: 3, pl: 3, pr: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Enviar Notificação
            </Typography>
          </Grid>



          <Grid item sx={{ mt: 1, width: '100%', px: 3 }}>
            <TextField
              fullWidth
              label="Título da Notificação"
              value={notificationTitle}
              onChange={(e) => setNotificationTitle(e.target.value)}
            />
          </Grid>

          <Grid item sx={{ mt: 1, width: '100%', px: 3 }}>
            <TextField
              fullWidth
              label="Conteúdo da Notificação"
              multiline
              rows={4}
              value={notificationContent}
              onChange={(e) => setNotificationContent(e.target.value)}
            />
          </Grid>

          <Grid item sx={{ width: '100%', mt: 0, mb: -1, pl: 3, pr: 3 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Grupos que deseja notificar:
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{
            width: '100%',
            paddingLeft: 3,
            paddingRight: 3,
            maxHeight: '300px',
            overflow: 'auto'
          }}>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={selectedGrupos.length > 0 && selectedGrupos.length < grupos.length}
                      onChange={handleSelectAll}
                    />
                  }
                  label="Selecionar Todos"
                />
              </Grid>
              {grupos.map(grupo => (
                <Grid container key={grupo.id}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedGrupos.includes(grupo.id)}
                          onChange={() => handleCheckboxChange(grupo.id)}
                        />
                      }
                      label={grupo.nome}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>


          <Grid item sx={{ width: '75%', paddingLeft: 3, paddingRight: 3, mb: 1 }} >
            <Button
              variant="contained"
              size="small"
              fullWidth
              onClick={handleSendNotification}
              disabled={!notificationTitle || !notificationContent || selectedGrupos.length === 0 || isDisable}
              sx={{
                borderRadius: '16px',
                textTransform: 'none',
              }}>
              Enviar
            </Button>
          </Grid>

        </Grid>
      </Grid>
    </Box>
  );

}
