import { createSlice } from "@reduxjs/toolkit";

const getMonthStartEnd = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const end = new Date(now.getFullYear(), now.getMonth() + 2, 0);
  return {
    start: start.toISOString().split('T')[0],
    end: end.toISOString().split('T')[0]
  };
};


const { start, end } = getMonthStartEnd();

const initialState = {
    dataIni: start,
    dataFim: end
}

export const dateSlice = createSlice({
    name: 'dateRange',
    initialState,
    reducers: {
        setDataIni: (state, action) => {
            state.dataIni = action.payload;
        },
        setDataFim: (state, action) => {
            state.dataFim = action.payload;
        },
    }
});

export const { setDataIni, setDataFim } = dateSlice.actions;
export default dateSlice.reducer;